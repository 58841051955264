import React, { Fragment, useState } from 'react'
import { Panel, Button, Feedback } from '@wf-wfria/ria'
import { IconVerify } from '@wf-wfria/ria-icons'
import { FormattedMessage } from 'react-intl'
import '@wf-wfria/ria/lib/css/Flexgrid/Flexgrid.css'
import {
	WFORMS,
	RFI,
	ONBOARD,
	DLO,
	COBO,
	EASYCONNECT,
	SBA,
	PLINFOLEASE,
	RICOH,
	WFINFOLEASE
} from '../constants/businessSegmentBrandIds'
import {
	BasicText,
	BasicPrintArea,
	BasicPrintButton
} from '@wf-escon/react-data-driven-components'

export function TermsAndConditionsContent({ termsToDisplay }) {
	return (
		<div data-wf-efmya-rfi-toc id="TermsAndConditionsContent">
			<BasicText text={termsToDisplay} inline={true} />
		</div>
	)
}

export function TermsAndConditionsDeclineButton({
	onDeclineTerms,
	mode,
	formatMessage
}) {
	const [panelOpen, setPanelOpen] = useState(false)
	function togglePanelOpen() {
		setPanelOpen(!panelOpen)
	}
	function declineContent(mode) {
		if (mode === 'sba') {
			return formatMessage({
				id: 'terms-and-conditions-page.alert.message'
			})
		}
		if (mode === 'easyconnect' || mode === 'dlo' || mode === 'cobo') {
			return formatMessage({
				id: 'terms-and-conditions-page.alert.message-easyconnect'
			})
		}
		return formatMessage({
			id: 'terms-and-conditions-page.alert.message-onboard'
		})
	}
	return (
		<Panel
			onClose={togglePanelOpen}
			title={formatMessage({
				id: 'terms-and-conditions-decline-verification'
			})}
			open={panelOpen}
			trigger={
				<span data-wf-efmya-terms-and-conditions-decline-button>
					<Button
						label={formatMessage({
							id: 'terms-and-conditions.footer.button.decline.label'
						})}
						kind="chromeless"
						onClick={togglePanelOpen}
					/>
				</span>
			}
			footer={
				<div data-wf-efmya-terms-and-conditions-decline-footer>
					<Button
						kind="primary"
						label={formatMessage({
							id: 'terms-and-conditions.footer.button.decline.label'
						})}
						onClick={onDeclineTerms}
					/>
					<Button
						kind="chromeless"
						label={formatMessage({
							id: 'terms-and-conditions.footer.button.cancel.label'
						})}
						onClick={togglePanelOpen}
					/>
				</div>
			}
		>
			<Feedback
				icon={
					<IconVerify
						size="large"
						className="terms-and-condition-verify-icon"
					/>
				}
				size="large"
			>
				<p>{declineContent(mode)}</p>
			</Feedback>
		</Panel>
	)
}
export function TermsAndConditionsFooter({
	showTermsFooter,
	mode,
	onShowCreateUserId,
	onHideTerms,
	onDeclineTerms,
	formatMessage
}) {
	if (showTermsFooter) {
		return (
			<div>
				<span className="TermsAndConditionsFooter_Text">
					<strong>
						<FormattedMessage id="terms-and-conditions.footer.instruction" />
					</strong>
				</span>
				<Button
					className="terms-accept-button"
					kind="primary"
					onClick={() => {
						onShowCreateUserId()
					}}
				>
					<FormattedMessage id="terms-and-conditions.footer.button.accept.label" />
				</Button>
				<TermsAndConditionsDeclineButton
					mode={mode}
					onDeclineTerms={onDeclineTerms}
					formatMessage={formatMessage}
				/>
			</div>
		)
	}
	if (
		mode !== WFORMS &&
		mode !== ONBOARD &&
		mode !== SBA &&
		mode !== RFI &&
		mode !== DLO &&
		mode !== COBO &&
		mode !== EASYCONNECT
	) {
		return (
			<div>
				<Button
					className="terms-close-button"
					kind="primary"
					onClick={() => onHideTerms()}
				>
					<FormattedMessage id="terms-and-conditions.footer.button.close.label" />
				</Button>
			</div>
		)
	}
	return <Fragment />
}

export function TermsAndConditions({
	show,
	showTermsFooter,
	termsToDisplay,
	termType,
	mode,
	onHideTerms,
	onShowCreateUserId,
	onDeclineTerms,
	intl
}) {
	window.scrollTo(0, 1)
	const { formatMessage } = intl
	const headerMessageId =
		termType && termType.toLowerCase().includes('privacy')
			? 'terms-and-conditions.header.modal-dialog.policies.title'
			: 'terms-and-conditions.header.modal-dialog.terms.title'
	if (!show) {
		return null
	}
	if (mode === WFINFOLEASE || mode === RICOH || mode === PLINFOLEASE) {
		return (
			<div className="TermsAndConditions">
				<div data-wf-efmya-media="print" style={{ display: 'none' }} />
				<BasicPrintArea>
					<BasicPrintButton
						label={formatMessage({
							id: 'terms-and-conditions-print-button'
						})}
					/>
					<h1>{formatMessage({ id: headerMessageId })}</h1>
					<TermsAndConditionsContent
						termsToDisplay={termsToDisplay}
						onShowCreateUserId={onShowCreateUserId}
					/>
				</BasicPrintArea>
				<TermsAndConditionsFooter
					showTermsFooter={showTermsFooter}
					mode={mode}
					onHideTerms={onHideTerms}
					onShowCreateUserId={onShowCreateUserId}
					onDeclineTerms={onDeclineTerms}
					formatMessage={formatMessage}
				/>
			</div>
		)
	}
	return (
		<Panel
			className="TermsAndConditions"
			footer={
				<TermsAndConditionsFooter
					showTermsFooter={showTermsFooter}
					mode={mode}
					onHideTerms={onHideTerms}
					onShowCreateUserId={onShowCreateUserId}
					onDeclineTerms={onDeclineTerms}
					formatMessage={formatMessage}
				/>
			}
			onClose={onHideTerms}
			open={show}
			title={formatMessage({
				id: headerMessageId
			})}
			height="60%"
			elementToFocusOnClose=""
		>
			<TermsAndConditionsContent
				termsToDisplay={termsToDisplay}
				onShowCreateUserId={onShowCreateUserId}
			/>
		</Panel>
	)
}
