import React, { useState, useEffect } from 'react'
import { Container, Form, Button, TextInput, Feedback } from '@wf-wfria/ria'
import { IconError } from '@wf-wfria/ria-icons/'
import ForgotUserId from '../containers/ForgotUserId'
import ForgetPassword from '../containers/ForgetPassword'
import { FormattedMessage } from 'react-intl'
import {
	NUDATACAPTCHAID,
	NUDATAMESSAGEID,
	OCRACAPTCHAID,
	OCRAMESSAGEID
} from '../../constants/captchaElementIds'

export function Login({
	intl,
	onSignOnClick,
	signOnMessage,
	onlineHelpUrl,
	beginNuDataMonitoring,
	showCaptchaForm,
	showForgotUserIdLink
}) {
	const { formatMessage } = intl
	const [isFormValid, setFormValidity] = useState(false)
	const [values, setValues] = useState({})
	const [isCaptchaObserved, setIsCaptchaObserved] = useState(false)

	function handleValueChange({ name, value }) {
		setValues({
			...values,
			[name]: value
		})
	}
	function handleOnSignOnClick() {
		const captchaValues = document.getElementById('nucaptcha-answer')?.value
		if (captchaValues) {
			const updatedValues = {
				...values,
				nuCaptchaAns: captchaValues
			}
			onSignOnClick(updatedValues)
		} else {
			onSignOnClick(values)
		}
	}

	function handleValidation(isFormValid) {
		setFormValidity(isFormValid)
	}

	useEffect(() => {
		beginNuDataMonitoring()
	}, [beginNuDataMonitoring])

	useEffect(() => {
		if (!isCaptchaObserved) {
			const captchaMessageEl = document.getElementById(NUDATAMESSAGEID)
			const captchaEl = document.getElementById(NUDATACAPTCHAID)
			const observerConfig = { childList: true }
			const messageObserver = new MutationObserver(function() {
				updateOcraCaptchaValues(NUDATAMESSAGEID, OCRAMESSAGEID)
			})
			const captchaObserver = new MutationObserver(function() {
				updateOcraCaptchaValues(NUDATACAPTCHAID, OCRACAPTCHAID)
			})
			messageObserver.observe(captchaMessageEl, observerConfig)
			captchaObserver.observe(captchaEl, observerConfig)
			setIsCaptchaObserved(true)
		}
	}, [showCaptchaForm, isCaptchaObserved])

	return (
		<Container
			title={formatMessage({ id: 'ocra.sign.on.container.title' })}
			className="wf-col--desk-5 wf-col--tab-12 wf-col--mob-only-12 LoginContainer"
		>
			<Form onValidate={handleValidation} className="container wf-row--padding">
				{showCaptchaForm && (
					<div
						className="wf-col--12 NuDataContainer"
						id="ocraCaptchaMessageContainer"
					/>
				)}
				<TextInput
					className="wf-col--12"
					name="userId"
					label={<FormattedMessage id="ocra.sign.on.input.user.id" />}
					required
					nativeValidationMessages={{
						valueMissing: formatMessage(
							{
								id: 'ocra.required.validation.message'
							},
							{
								fieldLabel: formatMessage({ id: 'ocra.sign.on.input.user.id' })
							}
						)
					}}
					onValueChange={handleValueChange}
				/>
				{showForgotUserIdLink && (
					<div className="RightAlign">
						<ForgotUserId
							title={formatMessage({
								id: 'ocra.sign.on.action.forgot.user.id'
							})}
							label={
								<FormattedMessage id="ocra.sign.on.action.forgot.user.id" />
							}
							className="ForgetLinks "
						/>
					</div>
				)}

				<TextInput
					className="wf-col--12"
					type="password"
					label={<FormattedMessage id="ocra.sign.on.input.password" />}
					name="password"
					required
					onValueChange={handleValueChange}
					nativeValidationMessages={{
						valueMissing: formatMessage(
							{
								id: 'ocra.required.validation.message'
							},
							{
								fieldLabel: formatMessage({ id: 'ocra.sign.on.input.password' })
							}
						)
					}}
				/>
				<div className="RightAlign">
					<ForgetPassword
						label={
							<FormattedMessage id="ocra.sign.on.action.forgot.user.password" />
						}
						className="ForgetLinks  wf-link-nodecoration"
					/>
				</div>
				{showCaptchaForm && (
					<div
						className="wf-col--12 NuDataContainer"
						id="ocraCaptchaContainer"
					/>
				)}
				<div className="wf-col--12">
					<SignOnErrorMessage signOnMessage={signOnMessage} />
					<Button
						name="SignOn"
						kind="primary"
						disabled={!isFormValid}
						onClick={handleOnSignOnClick}
						className="SignOn"
					>
						<FormattedMessage id="ocra.sign.on.button" />
					</Button>
				</div>
				<div className="wf-col--12 wf-u-text-align-center SignOnHelp">
					<Button
						name="signOnHelp"
						kind="simple"
						href={onlineHelpUrl}
						target="_blank"
						rel="noreferrer"
					>
						<FormattedMessage id="ocra.sign.on.help-logging-account.title" />
					</Button>
				</div>
			</Form>
		</Container>
	)
}

export function SignOnErrorMessage({ signOnMessage }) {
	const { show, messageId } = signOnMessage
	if (show) {
		return (
			<Feedback
				icon={<IconError size="medium" theme="alert" />}
				className="SignOnError"
				role="alert"
			>
				{messageId}
			</Feedback>
		)
	}
	return null
}

export function updateOcraCaptchaValues(nuDataElId, ocraElId) {
	const nuDataCaptchaEl = document.getElementById(nuDataElId)
	const ocraCaptchaEl = document.getElementById(ocraElId)
	const nuDataCaptchaContent = nuDataCaptchaEl?.childNodes[0] || null
	const ocraCaptchaContent = ocraCaptchaEl?.childNodes[0] || null
	if (
		nuDataCaptchaContent &&
		ocraCaptchaEl &&
		nuDataCaptchaContent !== ocraCaptchaContent
	) {
		ocraCaptchaContent
			? ocraCaptchaEl.replaceChild(nuDataCaptchaContent, ocraCaptchaContent)
			: ocraCaptchaEl.appendChild(nuDataCaptchaContent)
	}
}
