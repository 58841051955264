import { connect } from 'react-redux'
import { Faqs } from '../components/Faqs'
import { injectIntl } from 'react-intl'
import {
	updateInput,
	submitFeedback,
	hideFeedback,
	updateAllowContactRegardingFeedback
} from '../actions/login'
import { setShowFeedbackSubmitButton } from './FeedbackForm'
export const mapStateToProps = state => {
	const {
		inputFields: { feedbackText, feedbackEmail },
		showFeedbackForm,
		allowContactRegardingFeedback,
		componentDetails
	} = state.loginWidget
	const { feedbackMessage } = state.messages
	return {
		feedbackText,
		feedbackEmail,
		charactersRemaining: 1000 - feedbackText.value.length,
		showFeedbackForm,
		showFeedbackSubmitButton: setShowFeedbackSubmitButton(
			feedbackText,
			feedbackEmail,
			allowContactRegardingFeedback
		),
		allowContactRegardingFeedback,
		feedbackMessage,
		showTermsAndPolicies: state.termsAndPolicies.showTermsAndPolicies,
		mode: state.loginWidget.mode,
		showForgotUserIdLink: componentDetails?.showForgotUserIdLink || false
	}
}

export const mapDispatchToProps = dispatch => {
	return {
		onUpdateAllowContactRegardingFeedback(allowContactRegardingFeedback) {
			dispatch(
				updateAllowContactRegardingFeedback(allowContactRegardingFeedback)
			)
		},
		onSubmitFeedback() {
			dispatch(submitFeedback())
		},
		onHideFeedback() {
			dispatch(hideFeedback())
		},
		onUpdateInput(elementId, inputValue) {
			dispatch(updateInput(elementId, inputValue))
		}
	}
}

export default injectIntl(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Faqs)
)
