import { connect } from 'react-redux'
import { ForgotUserIdForm } from '../components/ForgotUserIdForm'
import {
	blurInput,
	focusInput,
	getUserId,
	hideForgotUserId,
	updateInput,
	clearContractOrInvoice,
	selectContractOrInvoice
} from '../actions/login'
import { hideAllMessages, showForgotUserIdMessage } from '../actions/messages'
import { injectIntl } from 'react-intl'
import {
	WFINFOLEASE,
	RICOH,
	PLINFOLEASE,
	SBA,
	ONBOARD,
	DLO,
	COBO,
	EASYCONNECT
} from '../constants/businessSegmentBrandIds'

export const mapStateToProps = state => {
	const {
		contractNumber,
		emailForgotUser,
		phoneNumber,
		customerNumberRicohForgotUserId,
		contractNumberRicohForgotUserId,
		invoiceNumberRicohForgotUserId
	} = state.loginWidget.inputFields
	const { mode, showForgotUserIdForm } = state.loginWidget
	const { contractOrInvoice, focusedElement } = state.loginWidget

	return {
		emailForgotUser,
		phoneNumber,
		contractNumber,
		customerNumberRicohForgotUserId,
		contractNumberRicohForgotUserId,
		invoiceNumberRicohForgotUserId,
		forgotUserIdMessage: state.messages.forgotUserIdMessage,
		showForgotUserIdForm,
		mode,
		contractOrInvoice,
		focusedElement,
		submitButtonDisabled: getSubmitButtonDisabled(
			emailForgotUser,
			phoneNumber,
			mode,
			contractNumber,
			customerNumberRicohForgotUserId,
			contractNumberRicohForgotUserId,
			invoiceNumberRicohForgotUserId
		)
	}
}

function isViolation(str) {
	return str.errorIds.length > 0 || str.value.length === 0
}

export function getSubmitButtonDisabled(
	emailForgotUser,
	phoneNumber,
	mode,
	contractNumber,
	customerNumberRicohForgotUserId,
	contractNumberRicohForgotUserId,
	invoiceNumberRicohForgotUserId
) {
	if (
		mode === SBA ||
		mode === ONBOARD ||
		mode === DLO ||
		mode === COBO ||
		mode === EASYCONNECT
	) {
		return isViolation(emailForgotUser) || isViolation(phoneNumber)
	} else if (mode === WFINFOLEASE || mode === PLINFOLEASE) {
		return isViolation(emailForgotUser) || isViolation(contractNumber)
	} else if (mode === RICOH) {
		const contractNumberOrInvoiceNumberViolation =
			isViolation(contractNumberRicohForgotUserId) &&
			isViolation(invoiceNumberRicohForgotUserId)
		return (
			isViolation(emailForgotUser) ||
			isViolation(customerNumberRicohForgotUserId) ||
			contractNumberOrInvoiceNumberViolation
		)
	} else {
		return isViolation(emailForgotUser)
	}
}

export const mapDispatchToProps = dispatch => {
	return {
		onGetUserId() {
			dispatch(getUserId())
		},
		onHideForgotUserId() {
			dispatch(hideAllMessages())
			dispatch(hideForgotUserId())
		},
		onUpdateInput(elementId, inputValue) {
			dispatch(updateInput(elementId, inputValue))
		},
		onFocusInput(elementId) {
			dispatch(focusInput(elementId, 'ForgotUserIdForm'))
		},
		onBlurInput(elementId) {
			dispatch(blurInput(elementId))
		},
		onShowForgotUserIdMessage(message) {
			dispatch(showForgotUserIdMessage(message))
		},
		onHideAllMessages() {
			dispatch(hideAllMessages())
		},
		onSelectContractOrInvoice(contractOrInvoice, mode) {
			dispatch(clearContractOrInvoice(contractOrInvoice, mode))
			dispatch(selectContractOrInvoice(contractOrInvoice))
		}
	}
}

export default injectIntl(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(ForgotUserIdForm)
)
