import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import get from 'lodash.get'
import { CreateUserIdForm } from '../components/CreateUserIdForm'
import {
	blurInput,
	focusInput,
	hideCreateUserId,
	submitNewUserId,
	updateInput,
	setDeviceType,
	updateLanguagePreference
} from '../actions/login'
import {
	WFORMS,
	RFI,
	ONBOARD,
	SBA,
	COBO,
	DLO,
	EASYCONNECT
} from '../constants/businessSegmentBrandIds'

export const mapStateToProps = state => {
	const messages = state.loginWidget.inputFields.newUserId.errorIds
	const {
		mode,
		inputFields,
		deviceType,
		languagePreference,
		sbaDetails,
		showCreateUserIdForm,
		showUserIdConfirmationResponse
	} = state.loginWidget
	const { showLanguageDropDown } = state.locale
	return {
		inputFields,
		deviceType,
		languagePreference,
		showLanguageDropDown,
		sbaDetails,
		showCreateUserIdForm,
		createUserIdMessage: state.messages.createUserIdMessage,
		formIsValid: validateCreateUserIdFormInputs(mode, inputFields),
		messages: chooseRelevantMessage(messages),
		showUserIdConfirmationResponse,
		userIdClass:
			messages.length && inputFields.newUserId.value.length
				? 'ValidateUserIDField'
				: '',
		mode
	}
}

export function getNewUserIdByBrand(mode, inputFields) {
	const { newUserId, newUserIdNotCC } = inputFields
	if (
		mode === WFORMS ||
		mode === WFORMS ||
		mode === ONBOARD ||
		mode === SBA ||
		mode === DLO ||
		mode === COBO ||
		mode === EASYCONNECT
	) {
		return newUserId
	}
	return newUserIdNotCC
}

export function chooseRelevantMessage(messages) {
	const allowedCharsMessage = messages.find(message => {
		return message.messageId.includes('allowed-chars')
	})
	if (allowedCharsMessage) {
		return [allowedCharsMessage]
	}
	return messages
}

export function validateCreateUserIdFormInputs(mode, inputFields) {
	const inputFieldsArr = new Array(getNewUserIdByBrand(mode, inputFields))
	if (
		mode !== WFORMS &&
		mode !== WFORMS &&
		mode !== ONBOARD &&
		mode !== SBA &&
		mode !== RFI &&
		mode !== DLO &&
		mode !== COBO &&
		mode !== EASYCONNECT
	) {
		inputFieldsArr.push(
			inputFields.firstName,
			inputFields.lastName,
			inputFields.company,
			inputFields.phone,
			getNewUserIdByBrand(mode, inputFields)
		)
	}
	let formIsValid = true

	if (inputFields.title) {
		formIsValid =
			get(inputFields, 'title.errorIds.length', 0) > 0 ? false : true
	}
	inputFieldsArr.forEach(inputField => {
		if (inputField.errorIds.length > 0 || inputField.value.length === 0) {
			formIsValid = false
		}
	})
	return formIsValid
}

export const mapDispatchToProps = dispatch => {
	return {
		onUpdateInput(elementId, inputValue) {
			dispatch(updateInput(elementId, inputValue))
		},
		onFocusInput(elementId) {
			dispatch(focusInput(elementId, 'CreateUserIdForm'))
		},
		onBlurInput(elementId) {
			dispatch(blurInput(elementId))
		},
		onHideCreateUserId() {
			dispatch(hideCreateUserId())
		},
		onSubmitNewUserId() {
			dispatch(submitNewUserId())
		},
		onDeviceTypeChange(deviceType) {
			dispatch(setDeviceType(deviceType))
		},
		onLanguagePreferenceChange(languagePreference) {
			dispatch(updateLanguagePreference(languagePreference))
		}
	}
}

export default injectIntl(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(CreateUserIdForm)
)
