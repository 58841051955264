import {
	SET_DELIVERY_MODE,
	CLOSE_DELIVERY_MODE_MODAL,
	SET_TERMS_OF_USE,
	HIDE_TERMS_OF_USE,
	GET_REGISTRATION_DETAILS_SUCCESS,
	CLOSE_REGISTRATION_DETAILS_MODAL,
	SHOW_SYSTEM_MESSAGES,
	GET_CONTACT_DETAILS_SUCCESS,
	HIDE_SYSTEM_MESSAGES,
	SET_REGION,
	SET_TERMS_OF_USE_FOR_FOOTER,
	SHOW_CAPTCHA_FORM,
	HIDE_CAPTCHA_FORM
} from '../actions/login'

export const defaultState = {
	get showTermsOfUse() {
		return false
	},
	get region() {
		return 'NA'
	},
	get termsOfUse() {
		return ''
	},
	get showRegistrationDetails() {
		return false
	},
	get registrationDetails() {
		return {}
	},
	get messages() {
		return []
	},
	get mode() {
		return {}
	},
	get showDeliveryMode() {
		return false
	},
	get contactDetails() {
		return []
	},
	get showCaptchaForm() {
		return false
	}
}

export function ocra(state = defaultState, action) {
	switch (action.type) {
		case SET_TERMS_OF_USE:
			return {
				...state,
				showTermsOfUse: true,
				termsOfUse: action.termsOfUse
			}
		case SET_TERMS_OF_USE_FOR_FOOTER:
			return {
				...state,
				showTermsOfUse: false,
				termsOfUse: action.termsOfUse
			}
		case SET_REGION:
			return {
				...state,
				region: action.region
			}
		case HIDE_TERMS_OF_USE:
			return {
				...state,
				showTermsOfUse: false,
				termsOfUse: ''
			}
		case GET_REGISTRATION_DETAILS_SUCCESS:
			return {
				...state,
				showRegistrationDetails: true,
				registrationDetails: action.registrationDetails
			}
		case CLOSE_REGISTRATION_DETAILS_MODAL:
			return {
				...state,
				showRegistrationDetails: false
			}
		case SET_DELIVERY_MODE:
			return {
				...state,
				mode: action.mode,
				showDeliveryMode: true
			}
		case CLOSE_DELIVERY_MODE_MODAL:
			return {
				...state,
				showDeliveryMode: false
			}
		case SHOW_SYSTEM_MESSAGES:
			return {
				...state,
				messages: action.messages
			}
		case HIDE_SYSTEM_MESSAGES:
			return {
				...state,
				messages: []
			}
		case GET_CONTACT_DETAILS_SUCCESS:
			return {
				...state,
				contactDetails: action.contactDetails
			}
		case SHOW_CAPTCHA_FORM:
			return {
				...state,
				showCaptchaForm: true
			}
		case HIDE_CAPTCHA_FORM:
			return {
				...state,
				showCaptchaForm: false
			}
		default:
			return state
	}
}
