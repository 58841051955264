import { POST } from '@wf-escon/authority'
import {
	WFINFOLEASE,
	RICOH,
	PLINFOLEASE
} from '../constants/businessSegmentBrandIds'

export const SUBMIT_FEEDBACK_PATH = 'feedback/v1/submit.json'
export const MYACCOUNTS_SUBMIT_FEEDBACK_PATH = 'slpfeedback/submit.json'

export function fetchSubmitFeedback(feedback, mode) {
	const PATH = getSubmitFeedbackByMode(mode)
	return POST(PATH, feedback)
}

export function getSubmitFeedbackByMode(mode) {
	switch (mode) {
		case WFINFOLEASE:
		case RICOH:
		case PLINFOLEASE:
			return MYACCOUNTS_SUBMIT_FEEDBACK_PATH
		default:
			return SUBMIT_FEEDBACK_PATH
	}
}
