import { validationRule } from './validation/ocraValidationRule'

const defaultCreateUserIdFields = [
	{
		tag: 'TextInput',
		name: 'firstName',
		label: 'ocra.create-user-id.first-name.label',
		order: 1,
		className: 'wf-col--desk-8 wf-col--tab-12 wf-col--mon-only-12',
		required: true,
		validationType: validationRule['firstName']
	},
	{
		tag: 'TextInput',
		name: 'middleInitial',
		order: 2,
		label: 'ocra.create-user-id.middle-initial.label',
		className:
			'wf-col--desk-4 wf-col--tab-12 wf-col--mon-only-12 middleInitial',
		validationType: validationRule['middleInitial']
	},
	{
		tag: 'TextInput',
		name: 'lastName',
		order: 3,
		label: 'ocra.create-user-id.last-name.label',
		className: 'wf-col--desk-8 wf-col--tab-12 wf-col--mon-only-12',
		required: true,
		validationType: validationRule['lastName']
	},
	{
		tag: 'Select',
		name: 'contactPreferences',
		order: 7,
		label: 'ocra.create-user-id.device-type.label',
		className: 'wf-col--desk-8 wf-col--tab-12 wf-col--mon-only-12',
		required: true,
		choices: [
			{
				label: 'ocra.create-user-id.contact.landline.label',
				value: 'landline'
			},
			{
				label: 'ocra.create-user-id.contact.mobile.label',
				value: 'cell'
			}
		]
	},
	{
		tag: 'TextInput',
		name: 'telephone',
		order: 9,
		label: 'ocra.create-user-id.phone.label',
		className: 'wf-col--desk-8 wf-col--tab-12 wf-col--mon-only-12',
		required: true,
		validationType: validationRule['telephone']
	},
	{
		tag: 'TextInput',
		name: 'telephoneExt',
		order: 10,
		label: 'ocra.create-user-id.ext.label',
		className: 'wf-col--desk-4 wf-col--tab-12 wf-col--mon-only-12',
		validationType: validationRule['ext']
	},
	{
		tag: 'TextInput',
		order: 12,
		name: 'username',
		label: 'ocra.create-user-id.user-id.label',
		className: 'wf-col--desk-8 wf-col--tab-12 wf-col--mon-only-12',
		required: true,
		validationType: validationRule['userId']
	}
]

export const fields = {
	forgotUserId: {
		ocra: [
			{
				tag: 'TextInput',
				name: 'emailAddress',
				label: 'ocra.forgot-user-id.email.label',
				className: 'wf-row api-xsmall-margin-bottom',
				required: true,
				validationType: validationRule['email']
			}
		]
	},
	createUserId: {
		ocra: defaultCreateUserIdFields,
		ocraEMEA: [...defaultCreateUserIdFields.filter(field => field.order !== 6)]
	}
}
