import React, { useState, useEffect } from 'react'
import { Button, Panel } from '@wf-wfria/ria'
import { BasicText } from '@wf-escon/react-data-driven-components'

export function TermsOfUse({
	intl,
	show,
	content,
	title,
	submitLabel,
	cancelLabel,
	elementToFocusOnClose,
	onCloseTermsOfUse,
	onAccept
}) {
	const [disabled, setDisabled] = useState(true)
	const { formatMessage } = intl
	function onAcceptClick() {
		onCloseTermsOfUse()
		onAccept()
	}

	useEffect(() => {
		setTimeout(function() {
			const panel = document.querySelector('.wf-panel__body')
			if (panel) {
				const { scrollHeight, clientHeight } = panel
				if (scrollHeight <= clientHeight) {
					setDisabled(false)
				}
			}
		}, 1)
	}, [content])

	function handleScroll(e) {
		const { scrollHeight, scrollTop, clientHeight } = e.target
		const scrollBottom = scrollHeight - scrollTop <= clientHeight + 1
		if (scrollBottom) {
			setDisabled(false)
		}
		return null
	}

	return (
		<Panel
			className="OCRA_TermsOfUse"
			onClose={onCloseTermsOfUse}
			open={show}
			customStrings={{
				closeButtonAriaLabel: formatMessage({
					id: 'ocra.application.dialog.close'
				})
			}}
			onScroll={handleScroll}
			title={formatMessage({ id: title })}
			elementToFocusOnClose={elementToFocusOnClose}
			footer={
				<React.Fragment>
					<Button
						kind="primary"
						label={formatMessage({ id: submitLabel })}
						onClick={onAcceptClick}
						disabled={disabled}
					/>
					<Button
						kind="standard"
						label={formatMessage({ id: cancelLabel })}
						onClick={onCloseTermsOfUse}
					/>
				</React.Fragment>
			}
		>
			<BasicText text={content} wrapped={true} />
		</Panel>
	)
}
