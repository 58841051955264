const languageObj = {
	FRENCH: 'fr',
	ENGLISH: 'en',
	GERMAN: 'de'
}
export const formatHTMLLang = lang => {
	switch (lang) {
		case 'de_DE':
			return languageObj.GERMAN
		case 'en':
		case 'en_GB':
		case 'au_AU':
			return languageObj.ENGLISH
		case 'fr_FR':
		case 'fr':
			return languageObj.FRENCH
		default:
			return languageObj.ENGLISH
	}
}
