import React from 'react'
import { Button } from '@wf-wfria/ria'

export function ForgetPassword({ label, className, onForgetPassword }) {
	return (
		<Button kind="simple" className={className} onClick={onForgetPassword}>
			{label}
		</Button>
	)
}
