import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { Login } from '../components/Login'
import { signOn, beginNuDataMonitoring } from '../actions/login'

export const mapStateToProps = state => {
	const { signOnMessage } = state.messages
	const { onlineHelpUrl, componentDetails } = state.loginWidget
	const { showCaptchaForm } = state.ocra
	return {
		signOnMessage,
		onlineHelpUrl,
		showCaptchaForm,
		showForgotUserIdLink: componentDetails?.showForgotUserIdLink || false
	}
}

export const mapDispatchToProps = dispatch => {
	return {
		onSignOnClick(params) {
			dispatch(signOn(params))
		},
		beginNuDataMonitoring() {
			dispatch(beginNuDataMonitoring())
		}
	}
}

export default injectIntl(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(Login)
)
