import React, { useState } from 'react'
import { Container, Button, TextInput, RadioButton, Panel } from '@wf-wfria/ria'
import { BasicNoContent } from '@wf-escon/react-data-driven-components'
import { FormattedMessage } from 'react-intl'
import { onInputValidation } from './FieldsBuilder'
import { validationRule } from '../../helpers/validation/ocraValidationRule'
import TermsOfUse from '../containers/TermsOfUse'
import CreateUserId from '../containers/CreateUserId'
import DeliveryMode from '../containers/DeliveryMode'

export function CreateAccount({
	intl,
	mode = 'DEFAULT',
	onUserRegisterConfirmation,
	onTermsOfUseAccept,
	contactDetails
}) {
	const { formatMessage } = intl
	return (
		<Container
			title={formatMessage({ id: 'ocra.new.application.container.title' })}
			className="wf-col--desk-6 wf-col--tab-12 wf-col--mob-only-12 CreateAccountContainer"
		>
			<NewApplicationFormEMEA
				mode={mode}
				formatMessage={formatMessage}
				onUserRegisterConfirmation={onUserRegisterConfirmation}
			/>
			<NewApplicationForm
				mode={mode}
				formatMessage={formatMessage}
				onUserRegisterConfirmation={onUserRegisterConfirmation}
				contactDetails={contactDetails}
			/>
			<TermsOfUse
				title="ocra.terms-of-use.header.title"
				submitLabel="ocra.terms-of-use.footer.button.accept.label"
				cancelLabel="ocra.terms-of-use.footer.button.cancel.label"
				elementToFocusOnClose="registerButton"
				onAccept={onTermsOfUseAccept}
			/>
			<CreateUserId />
			<DeliveryMode />
		</Container>
	)
}

export function NewApplicationFormEMEA({
	mode,
	formatMessage,
	onUserRegisterConfirmation
}) {
	const [values, setValues] = useState({})
	const isValid = RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)
	function handleValueChange({ name, value }) {
		setValues({
			...values,
			[name]: value
		})
	}

	function handleOnContinue() {
		const { emailAddress } = values
		onUserRegisterConfirmation({ emailAddress })
	}

	function handleValidation(isValid) {
		if (values.emailAddress && values.emailAddress.match(isValid)) {
			return false
		}
		return true
	}

	if (mode === 'EMEA' || mode === 'ANZ') {
		return (
			<div className="container wf-row--padding">
				<div className="wf-row--padding wf-col--12">
					<p className="wf-col--12">
						<FormattedMessage id="ocra.new.application.emea.type" />
					</p>
					<div className="wf-col--12">
						<EmailInput
							show={true}
							values={values}
							formatMessage={formatMessage}
							handleValueChange={handleValueChange}
							handleOnContinue={handleOnContinue}
							onInputValidation={onInputValidation}
						/>
					</div>
					<div className="wf-col--12">
						<Button
							name="registerButton"
							kind="primary"
							disabled={handleValidation(isValid)}
							onClick={handleOnContinue}
							className="RegisterButton"
						>
							<FormattedMessage id="ocra.new.application.button.continue" />
						</Button>
					</div>
				</div>
			</div>
		)
	}
	return <BasicNoContent />
}

export function NewApplicationForm({
	mode,
	formatMessage,
	onUserRegisterConfirmation,
	contactDetails
}) {
	const [showContactDetailsPanel, setContactDetailsPanel] = useState(false)
	const [showLearnMorePanel, setLearnMorePanel] = useState(false)
	const initialValues = { applicationType: 'dealer' }
	const [values, setValues] = useState(initialValues)
	const { applicationType } = values
	const isValid = RegExp(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i)
	function handleValueChange({ name, value }) {
		setValues({
			...values,
			[name]: value
		})
	}
	function handleOnContinue() {
		if (applicationType === 'dealer') {
			const { emailAddress } = values
			onUserRegisterConfirmation({ emailAddress })
		} else {
			window.open('https://cdf.wf.com/vendor-application/')
		}
	}
	function handleValidation(isValid) {
		if (
			(values.emailAddress &&
				values.emailAddress.match(isValid) &&
				applicationType) ||
			applicationType === 'vendor'
		) {
			return false
		}
		return true
	}
	function openContactDetailsPanel() {
		setContactDetailsPanel(true)
	}

	function openLearnMorePanel() {
		setLearnMorePanel(true)
	}

	function closeContactDetailsPanel() {
		setContactDetailsPanel(false)
	}

	function closeLearnMorePanel() {
		setLearnMorePanel(false)
	}

	if (mode === 'DEFAULT') {
		const creditFacilityAriaLabel = `${formatMessage({
			id: 'ocra.new.application.action.apply.credit.facility'
		})} ${formatMessage({
			id: 'ocra.new.application.description.credit.facility'
		})}`
		return (
			<>
				<div className="container wf-row--padding">
					<div className="wf-col--12">
						<p>
							<FormattedMessage id="ocra.new.application.description" />
							<br />
							<Button
								kind="simple"
								inline
								onClick={openLearnMorePanel}
								aria-label={formatMessage({
									id: 'ocra.new.application.action.learn.more.aria-label'
								})}
							>
								<FormattedMessage id="ocra.new.application.action.learn.more" />
							</Button>
						</p>
						<p>
							<Button
								kind="simple"
								onClick={openContactDetailsPanel}
								className="CreditFacilityClick"
								inline
								aria-label={creditFacilityAriaLabel}
							>
								<FormattedMessage id="ocra.new.application.action.apply.credit.facility" />
							</Button>
							<FormattedMessage id="ocra.new.application.description.credit.facility" />
						</p>
						<p>
							<FormattedMessage id="ocra.new.application.type" />
						</p>
					</div>
					<div className="wf-col--mob-only-12 wf-col--tab-12 wf-col--desk-12">
						<p>
							<FormattedMessage id="ocra.new.application.application.type.text" />
						</p>
						<fieldset>
							<legend className="wf-u-visually-hidden">
								<FormattedMessage id="ocra.new.application.application.type.text" />
							</legend>
							<RadioButton
								name="applicationType"
								value="dealer"
								className="ApplicationTypeButtons"
								label={formatMessage({
									id: 'ocra.new.application.type.label.dealer'
								})}
								onValueChange={handleValueChange}
								checked={applicationType === 'dealer'}
							/>
							<RadioButton
								value="vendor"
								name="applicationType"
								className="ApplicationTypeButtons"
								label={formatMessage({
									id: 'ocra.new.application.type.label.vendor'
								})}
								onValueChange={handleValueChange}
							/>
						</fieldset>
					</div>
					<EmailInput
						show={applicationType === 'dealer'}
						formatMessage={formatMessage}
						values={values}
						handleValueChange={handleValueChange}
						onInputValidation={onInputValidation}
						handleOnContinue={handleOnContinue}
					/>
					<div className="wf-col--12">
						<Button
							name="registerButton"
							kind="primary"
							disabled={handleValidation(isValid)}
							onClick={handleOnContinue}
							className="RegisterButton"
						>
							<FormattedMessage id="ocra.new.application.button.continue" />
						</Button>
					</div>
				</div>
				<ContactDetailsModal
					isPanelOpen={showContactDetailsPanel}
					closePanel={closeContactDetailsPanel}
					contactDetails={contactDetails}
					formatMessage={formatMessage}
				/>
				<LearnMoreModal
					isPanelOpen={showLearnMorePanel}
					closePanel={closeLearnMorePanel}
					formatMessage={formatMessage}
				/>
			</>
		)
	}

	return <BasicNoContent />
}

export function LearnMoreModal({ isPanelOpen, closePanel, formatMessage }) {
	return (
		<Panel
			onClose={closePanel}
			open={isPanelOpen}
			customStrings={{
				closeButtonAriaLabel: formatMessage({
					id: 'ocra.application.dialog.close'
				})
			}}
			title={formatMessage({
				id: 'ocra.new.application.learn.more.modal-dialog.title'
			})}
			trigger={<Button style={{ display: 'none' }} />}
			footer={
				<div>
					<Button
						kind="primary"
						label={formatMessage({
							id: 'ocra.new.application.learn.more.modal-dialog.cancel'
						})}
						onClick={closePanel}
					/>
				</div>
			}
		>
			<p>
				<strong>
					<FormattedMessage id="ocra.new.application.learn.more.modal-dialog.dealer" />
				</strong>
				<FormattedMessage id="ocra.new.application.learn.more.modal-dialog.dealer.text" />
			</p>
			<p>
				<strong>
					<FormattedMessage id="ocra.new.application.learn.more.modal-dialog.vendor" />
				</strong>
				<FormattedMessage id="ocra.new.application.learn.more.modal-dialog.vendor.text" />
			</p>
		</Panel>
	)
}
export function ContactDetailsModal({
	isPanelOpen,
	closePanel,
	contactDetails,
	formatMessage
}) {
	return (
		<Panel
			onClose={closePanel}
			open={isPanelOpen}
			customStrings={{
				closeButtonAriaLabel: formatMessage({
					id: 'ocra.application.dialog.close'
				})
			}}
			title={formatMessage({
				id: 'ocra.new.application.contact-details.modal-dialog.title'
			})}
			trigger={<Button style={{ display: 'none' }} />}
			footer={
				<div>
					<Button
						kind="primary"
						label={formatMessage({
							id: 'ocra.new.application.contact-details.modal-dialog.cancel'
						})}
						onClick={closePanel}
					/>
				</div>
			}
		>
			<dl className="dl-inline">
				{contactDetails.map(({ phoneNumber, text }, index) => {
					return (
						<React.Fragment key={index}>
							<dt className="wf-color-default">{phoneNumber}</dt>
							<dd>{text}</dd>
						</React.Fragment>
					)
				})}
			</dl>
		</Panel>
	)
}

export function EmailInput({
	show,
	values,
	formatMessage,
	handleValueChange,
	onInputValidation,
	handleOnContinue
}) {
	if (!show) {
		return <BasicNoContent />
	}
	function onEnterKeyDown(e) {
		if (e.keyCode === 13) {
			handleOnContinue(values)
		}
	}
	return (
		<TextInput
			required
			name="emailAddress"
			className="wf-col--11 wf-col--mob-only-12 wf-col--tab-12"
			label={formatMessage({ id: 'ocra.new.application.input.email' })}
			onValueChange={handleValueChange}
			onKeyDown={onEnterKeyDown}
			nativeValidationMessages={{
				valueMissing: formatMessage(
					{
						id: 'ocra.required.validation.message'
					},
					{
						fieldLabel: formatMessage({
							id: 'ocra.new.application.input.email'
						})
					}
				)
			}}
			customValidators={[
				value =>
					onInputValidation(
						formatMessage,
						value,
						formatMessage({ id: 'ocra.new.application.input.email' }),
						validationRule['email']
					)
			]}
		/>
	)
}
