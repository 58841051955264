import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Feedback } from '@wf-wfria/ria'
import { IconConfirmation, IconError } from '@wf-wfria/ria-icons'

export function SystemMessages({ messages }) {
	return messages.map((message, index) => {
		const { type, messageId } = message
		return (
			<div className="marginTop1" key={index} role="alert">
				<Feedback icon={getAppropriateIcon(type)} size="medium" padding border>
					<Messages messageId={messageId} />
				</Feedback>
			</div>
		)
	})
}

export function Messages({ messageId }) {
	if (Array.isArray(messageId)) {
		return (
			<ul>
				{messageId.map((id, index) => {
					return (
						<li key={index}>
							<FormattedMessage id={id} />
						</li>
					)
				})}
			</ul>
		)
	}
	return <FormattedMessage id={messageId} />
}

export function getAppropriateIcon(type) {
	if (type === 'error') {
		return <IconError size="medium" />
	} else {
		return <IconConfirmation size="medium" />
	}
}
