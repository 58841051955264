import React, { Fragment } from 'react'
import { WaitMessage } from '@wf-wfria/ria'

export function CustomWaitMessage({ isLoading }) {
	if (isLoading) {
		return (
			<WaitMessage
				render={() => <span className={'CustomWaitMessage'}>Loading...</span>}
			/>
		)
	}
	return <Fragment />
}
