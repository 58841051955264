import React, { Fragment, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Button, Panel, Form, RadioButton } from '@wf-wfria/ria'
import { SystemMessages } from './SystemMessages'

export function DeliveryMode({
	intl,
	show,
	mode,
	messages,
	onClose,
	onDeliveryModeSubmit
}) {
	const { formatMessage } = intl
	const { mobile, maskedEmail, maskedPhone } = mode
	const [formValues, setFormValues] = useState({})
	const [isFormValid, setFormValidity] = useState(false)
	function onChange({ name, value }) {
		setFormValues({ [name]: value })
	}
	function onSubmit() {
		onDeliveryModeSubmit(formValues)
	}
	function handleValidation(isFormValid) {
		setFormValidity(isFormValid)
	}
	return (
		<Panel
			className="OCRA_DeliveryMode"
			onClose={onClose}
			open={show}
			customStrings={{
				closeButtonAriaLabel: formatMessage({
					id: 'ocra.application.dialog.close'
				})
			}}
			title={formatMessage({ id: 'ocra.delivery-mode.new.user.setup.title' })}
			elementToFocusOnClose="registerButton"
			footer={
				<div>
					<Button
						kind="primary"
						label={formatMessage({
							id: 'ocra.delivery-mode.continue.button.label'
						})}
						disabled={!isFormValid}
						onClick={onSubmit}
					/>
					<Button
						kind="standard"
						label={formatMessage({
							id: 'ocra.delivery-mode.cancel.button.label'
						})}
						onClick={onClose}
					/>
				</div>
			}
		>
			<Fragment>
				<SystemMessages messages={messages} />
				<p className="wf-u-form-required-indicator">
					<FormattedMessage id="ocra.delivery-mode.required.field.label" />
				</p>
				<p className="wf-u-form-required-indicator">
					<FormattedMessage id="ocra.delivery-mode.content-header.instruction" />
				</p>
				<Form
					className="DeliveryMode PaddingTop3"
					onValidate={handleValidation}
				>
					<fieldset className="wf-row--padding">
						<legend className="wf-u-visually-hidden">
							<FormattedMessage id="ocra.delivery-mode.radio-button.fieldset.legend" />
						</legend>
						<Choice
							show={true}
							value="voice"
							onValueChange={onChange}
							label={
								maskedPhone +
								' ' +
								formatMessage({
									id: `ocra.delivery-mode.voice.option.value`
								})
							}
							intl
						/>
						<Choice
							show={maskedEmail}
							value="email"
							onValueChange={onChange}
							label={
								maskedEmail +
								' ' +
								formatMessage({
									id: `ocra.delivery-mode.email.option.value`
								})
							}
							intl
						/>
						<Choice
							show={mobile}
							value="text"
							onValueChange={onChange}
							label={
								maskedPhone +
								' ' +
								formatMessage({
									id: `ocra.delivery-mode.text.option.value`
								})
							}
							intl
						/>
					</fieldset>
					<div className="wf-row--padding">
						<p className="wf-col--12 Disclaimer">
							<FormattedMessage id="delivery-mode.content.disclaimer" />
						</p>
					</div>
				</Form>
			</Fragment>
		</Panel>
	)
}

export function Choice({ show, value, label, onValueChange }) {
	if (!show) {
		return null
	}
	return (
		<RadioButton
			className="wf-col--12"
			value={value}
			name="deliveryMode"
			onValueChange={onValueChange}
			label={label}
			required
		/>
	)
}
