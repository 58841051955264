import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { ForgetPassword } from '../components/ForgetPassword'
import { forgetPassword } from '../actions/login'

export const mapStateToProps = null

export const mapDispatchToProps = dispatch => {
	return {
		onForgetPassword() {
			dispatch(forgetPassword())
		}
	}
}

export default injectIntl(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(ForgetPassword)
)
