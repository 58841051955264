import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { App } from './App'
import {
	getRegistrationTermsOfUse,
	getAttorneyTermsOfUse,
	getBorrowerTermsOfUse,
	getAttorneyPrivacyPolicy,
	getTermsOfUse,
	getPrivacyPolicy
} from '../actions/termsAndPolicies'
import { getRegistrationTermsOfUse as ocragetRegistrationTermsOfUse } from '../OCRA/actions/login'
import { showFeedback, focusInput } from '../actions/login'
import { hideAllMessages } from '../actions/messages'
import { defaultState } from '../reducers/login'

export const mapStateToProps = state => {
	const { generalMessage, outageMessage } = state.messages
	const { locale, showLanguageDropDown } = state.locale
	const {
		widget,
		showLoading,
		componentDetails,
		componentDetailsOutage,
		productName,
		footerUrl,
		cookieConsentUrl,
		mode
	} = state.loginWidget
	const { auth } = state.loginWidget.componentDetails
	return {
		productName,
		footerUrl,
		cookieConsentUrl,
		mode,
		auth,
		widget,
		showLoading,
		componentDetailsOutage,
		hideWidgetContent: hideWidgetContent(componentDetails, defaultState),
		generalMessage,
		outageMessage,
		locale,
		showLanguageDropDown,
		termsOfUse: state.ocra.termsOfUse
	}
}

export function hideWidgetContent(componentDetails, defaultState) {
	const { outageManagement } = componentDetails
	return (
		(outageManagement && outageManagement.maintenanceMessageFlag === 'Y') ||
		JSON.stringify(componentDetails) ===
			JSON.stringify(defaultState.componentDetails)
	)
}

export const mapDispatchToProps = dispatch => {
	return {
		onShowFeedback() {
			dispatch(showFeedback())
		},
		onHideAllMessages() {
			dispatch(hideAllMessages())
		},
		onGetRegistrationTermsOfUse(showTermsFooter) {
			dispatch(getRegistrationTermsOfUse(showTermsFooter))
		},
		onGetAttorneyTermsOfUse() {
			dispatch(getAttorneyTermsOfUse())
		},
		onGetBorrowerTermsOfUse() {
			dispatch(getBorrowerTermsOfUse())
		},
		onGetAttorneyPrivacyPolicy() {
			dispatch(getAttorneyPrivacyPolicy())
		},
		onGetTermsOfUse() {
			dispatch(getTermsOfUse())
		},
		onGetPrivacyPolicy() {
			dispatch(getPrivacyPolicy())
		},
		onFocusInput(elementId, focusedForm) {
			dispatch(focusInput(elementId, focusedForm))
		},
		onChangeLanguageDropDown(event) {
			window.location.replace(event)
		},
		onTermsOfUse() {
			dispatch(ocragetRegistrationTermsOfUse(true))
		}
	}
}

export default injectIntl(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(App)
)
