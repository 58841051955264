import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { CreateAccount } from '../components/CreateAccount'
import {
	userRegistrationConfirmation,
	getRegistrationDetails
} from '../actions/login'

export const mapStateToProps = state => {
	const {
		ocra: { contactDetails }
	} = state
	return {
		contactDetails
	}
}

export const mapDispatchToProps = dispatch => {
	return {
		onUserRegisterConfirmation(params) {
			dispatch(userRegistrationConfirmation(params))
		},
		onTermsOfUseAccept() {
			dispatch(getRegistrationDetails())
		}
	}
}

export default injectIntl(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(CreateAccount)
)
