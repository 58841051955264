import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import {
	closeRegistrationDetailsModal,
	createNewUserId
} from '../actions/login'
import { CreateUserId } from '../components/CreateUserId'
import { fields } from '../../helpers/ocraFieldsConfig'

export const mapStateToProps = state => {
	const params = new URLSearchParams(window.location.search)
	const mode = params.get('mode')
	const locale = state.locale.locale
	const {
		ocra: { showRegistrationDetails, registrationDetails, region, messages }
	} = state
	const newAccountFields =
		mode && mode === 'ocraEMEA'
			? fields.createUserId['ocraEMEA']
			: fields.createUserId['ocra']
	if (mode && ['ocraEMEA', 'ocraANZ'].includes(mode) && !params.has('faqs')) {
		const phoneIndex = newAccountFields.findIndex(
			field => field.tag === 'TextInput' && field.name === 'telephone'
		)
		if (newAccountFields[phoneIndex]) {
			newAccountFields[phoneIndex].validationType = {
				pattern: /^[0-9]{3,14}$/,
				messageId: 'ocra.create-user-id-.emea.telephone-error.allowed-chars'
			}
		}
	}
	return {
		messages,
		show: showRegistrationDetails,
		region,
		locale,
		fields: newAccountFields,
		preRegisteredDetails: registrationDetails,
		mode
	}
}

export const mapDispatchToProps = dispatch => {
	return {
		onClose() {
			dispatch(closeRegistrationDetailsModal())
		},
		onCreateUserId(params) {
			dispatch(createNewUserId(params))
		}
	}
}

export default injectIntl(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(CreateUserId)
)
