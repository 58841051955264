import 'core-js/stable'
import 'regenerator-runtime/runtime'
import 'url-search-params-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import get from 'lodash.get'
import { Provider } from 'react-redux'
import asyncActions from 'redux-thunk'
import { createStore, applyMiddleware, compose } from 'redux'
import {
	setOrigin,
	setContext,
	setConfiguration,
	POST
} from '@wf-escon/authority'
import appReducer from './reducers'
import { getComponentDetails } from './actions/login'
import { appInit } from './app/appInit'
import App from './app/AppContainer'
import OCRA from './OCRA/app/AppContainer'
import { getOcraModeConfig } from './helpers/ocraModeConfig'
import LocalizedMessagesProvider from './containers/LocalizedMessagesProvider'
import { getContactDetails, setRegion } from './OCRA/actions/login'
import { name, version, config } from '../package.json'
;(function versions(name, version, window) {
	const versions = (window.versions = window.versions || {})
	/* istanbul ignore next */
	const values = new Set(versions[name])
	values.add(version)
	versions[name] = Array.from(values)
})(name, version, window)

const {
	senderApplicationId,
	senderHostName,
	senderMessageId,
	csrfHeaderId,
	csrfTokenId,
	apiContext
} = config

setOrigin(document.location.origin)
setContext(apiContext)
setConfiguration({
	senderApplicationId,
	senderHostName,
	senderMessageId,
	csrfHeaderId,
	csrfTokenId,
	'X-Requested-With': 'XMLHttpRequest'
})

const composeEnhancers = middleware => {
	// comment out before PR
	//return (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose)(middleware)
	return compose(middleware)
}

const store = createStore(
	appReducer,
	composeEnhancers(applyMiddleware(asyncActions))
)

export function initialize(getComponentDetailsError) {
	const componentDetailsError = getComponentDetailsError.error
		? getComponentDetailsError.error
		: null
	appInit(store, renderDom, componentDetailsError)
}

export async function refreshCsrf() {
	const timeoutInterval = 15 * 60 * 1000

	await refreshCSRFv2()

	store
		.dispatch(getComponentDetails())
		.then(response => {
			initialize(response)
		})
		.catch(error => {
			initialize(error)
		})

	setTimeout(refreshCsrf, timeoutInterval)
}

export function setCsrfTokenAndCsrfHeader(csrfToken, csrfHeader) {
	const token = document.getElementById('__csrftoken')
	if (token instanceof HTMLMetaElement) {
		token.setAttribute('content', csrfToken)
	}
	const header = document.getElementById('__csrfheader')
	if (header instanceof HTMLMetaElement) {
		header.setAttribute('content', csrfHeader)
	}
}

export async function refreshCSRFv2() {
	const response = await POST(`${apiContext}login/csrfToken.json`)
	const { csrfToken = '', csrfHeader = '' } = response || {}
	setCsrfTokenAndCsrfHeader(csrfToken, csrfHeader)
}
export async function fetchCsrf() {
	try {
		updateCsrfToken()
		store
			.dispatch(getComponentDetails())
			.then(response => {
				initialize(response)
			})
			.catch(error => {
				initialize(error)
			})
	} catch (error) {
		console.error('error', error)
	}
}

refreshCsrf()

export function renderDom() {
	ReactDOM.render(
		<Provider store={store}>
			<LocalizedMessagesProvider>
				<Application store={store} />
			</LocalizedMessagesProvider>
		</Provider>,
		document.getElementById('root')
	)
}

export function formatLocaleForOCRA(locale) {
	switch (locale) {
		case 'en':
			return 'en_US'
		case 'fr':
			return 'fr_CA'
		case 'de_DE':
			return 'de_DE'
		case 'fr_FR':
			return 'fr_FR'
		case 'en_GB':
			return 'en_GB'
		case 'au_AU':
			return 'au_AU'
		default:
			return 'en_US'
	}
}

export function Application({ store }) {
	const componentDetailsMode = get(store.getState(), 'loginWidget.mode', '')
	const params = new URLSearchParams(window.location.search)
	if (
		!params.has('faqs') &&
		!params.has('OCRA_Security') &&
		componentDetailsMode &&
		componentDetailsMode.includes('ocra')
	) {
		const { region } = getOcraModeConfig(componentDetailsMode)
		if (region) {
			if (region === 'NA') {
				store.dispatch(getContactDetails(region))
			}
			store.dispatch(setRegion(region))
			return (
				<OCRA
					mode={componentDetailsMode}
					region={region}
					onLanguageChange={locale => {
						onLanguageChange(locale, params)
					}}
				/>
			)
		}
	}
	return <App />
}

export function onLanguageChange(locale, params) {
	const componentDetailsMode = get(store.getState(), 'loginWidget.mode', '')
	if (params.has('locale')) {
		params.set('locale', formatLocaleForOCRA(locale))
	} else {
		params.append('locale', formatLocaleForOCRA(locale))
	}
	if (params.has('mode')) {
		params.set('mode', componentDetailsMode)
	} else {
		params.append('mode', componentDetailsMode)
	}
	window.location.replace('?' + params.toString())
}

/**
 * refreshCSRFv1Deprecated fetches the CSRF token and appends to dom.
 *
 * @deprecated use refreshCSRFv2
 * This function should be removed when SLP2 is implemented across all the applications.
 *
 * @returns {Promise<void>}
 */
export async function refreshCSRFv1Deprecated() {
	const response = await fetch(`${apiContext}CSRFGuardJavaScriptServlet.js`, {
		method: 'POST',
		headers: {
			'FETCH-CSRF-TOKEN': '1',
			'X-Requested-With': 'XMLHttpRequest'
		}
	})
	const data = await response.text()
	const token_pair = data.split(':')
	const token_name = token_pair[0]
	const token_value = token_pair[1]
	setCsrfTokenAndCsrfHeader(token_value, token_name)
}

export async function updateCsrfToken() {
	try {
		const options = {
			method: 'POST',
			headers: {
				'FETCH-CSRF-TOKEN': '1',
				'X-Requested-With': 'XMLHttpRequest'
			}
		}
		const response = await fetch(
			`${apiContext}CSRFGuardJavaScriptServlet.js`,
			options
		)

		const data = await response.text()
		const token_pair = data.split(':')
		const token_name = token_pair[0]
		const token_value = token_pair[1]

		setCsrfTokenAndCsrfHeader(token_value, token_name)
	} catch (error) {
		error(error)
	}
}
