import { POST } from '@wf-escon/authority'
import get from 'lodash.get'
import { WFINFOLEASE, RICOH } from '../constants/businessSegmentBrandIds'

export const SUBMIT_CONTRACTS_PATH_INFOLEASE =
	'register/ils/manageAccounts.json'
export const SUBMIT_CONTRACTS_PATH_RICOH = 'ricoh/register/groups.json'
export const SUBMIT_CONTRACTS_PATH_PRIVATE_LABEL =
	'register/ils/manageAccounts.json'

export function fetchSubmitContracts(mode, contracts, contractsInInitialForm) {
	let updatedContracts
	updatedContracts = constructUpdatedWFPLContractsInInitialForm(
		mode,
		contracts,
		contractsInInitialForm
	)
	const PATH = getPrivacyPolicyPathByBrandId(mode)
	return POST(PATH, updatedContracts)
}

export function getPrivacyPolicyPathByBrandId(mode) {
	switch (mode) {
		case WFINFOLEASE:
			return SUBMIT_CONTRACTS_PATH_INFOLEASE
		case RICOH:
			return SUBMIT_CONTRACTS_PATH_RICOH
		default:
			return SUBMIT_CONTRACTS_PATH_PRIVATE_LABEL
	}
}

export function constructUpdatedWFPLContractsInInitialForm(
	mode,
	contracts,
	contractsInInitialForm
) {
	const {
		existingContracts,
		newlyAddedContracts,
		modeOfAddingContracts
	} = contracts
	if (mode === RICOH) {
		const {
			registeredContractList,
			unRegisteredContractList
		} = contractsInInitialForm
		const newlyAddedContractsInInitialForm = unRegisteredContractList
			.map(contract => {
				const newlyAddedContractObject = newlyAddedContracts.find(
					newlyAddedContract => {
						return (
							newlyAddedContract.id ===
							get(contract, 'contractList[0].contractId', '000000')
						)
					}
				)
				const nickName =
					newlyAddedContractObject !== undefined
						? newlyAddedContractObject.nickName
						: null
				const id = newlyAddedContractObject ? newlyAddedContractObject.id : null
				return {
					billingId: '',
					idmsRegion: '',
					nickName,
					customerNumber: '',
					zipCode: '',
					createdDate: 0,
					contractNbrs: [id]
				}
			})
			.filter(contract => {
				return contract.nickName !== null
			})
		const existingContractsInInitialForm = registeredContractList.map(
			contract => {
				const existingContractObject = existingContracts.find(
					existingContract => {
						const finalId =
							Array.isArray(existingContract.id) &&
							existingContract.id.length !== 0
								? existingContract.id[0]
								: existingContract.id
						return (
							finalId === get(contract, 'contractList[0].contractId', '000000')
						)
					}
				)
				return {
					billingId: '',
					idmsRegion: '',
					nickName: existingContractObject.nickName || '',
					customerNumber: '',
					zipCode: '',
					createdDate: 0,
					contractNbrs: Array.isArray(existingContractObject.id)
						? existingContractObject.id
						: [existingContractObject.id]
				}
			}
		)
		return {
			contracts: existingContractsInInitialForm,
			contractList: newlyAddedContractsInInitialForm,
			autoEnrollFuture: modeOfAddingContracts === 'automatic'
		}
	} else {
		const {
			registeredAccountProfileList,
			customerAccountProfileList,
			zipAutoEnrollJsonList,
			customerName,
			customerNbr
		} = contractsInInitialForm
		const existingContractsInInitialForm = registeredAccountProfileList.map(
			contract => {
				const existingContractUnparsed = existingContracts.find(
					existingContract => {
						return existingContract.id === contract.contractNbrs.toString()
					}
				)
				const { nickName } = existingContractUnparsed
				return {
					...contract,
					nickName: nickName
				}
			}
		)
		const newlyAddedContractsInInitialForm = customerAccountProfileList
			.map(contract => {
				const contractWithChangedNickName = newlyAddedContracts.find(
					newlyAddedContract => {
						return newlyAddedContract.id === contract.contractNbrs.toString()
					}
				)
				if (
					contractWithChangedNickName &&
					contractWithChangedNickName.nickName
				) {
					return {
						...contract,
						nickName: contractWithChangedNickName.nickName
					}
				}
				if (
					contractWithChangedNickName &&
					contractWithChangedNickName.nickName === null
				) {
					return { ...contract }
				}
				return undefined
			})
			.filter(contract => contract !== undefined)
		return {
			registeredAccountProfileList: existingContractsInInitialForm,
			customerAccountProfileList: newlyAddedContractsInInitialForm,
			zipAutoEnrollJsonList,
			customerName,
			customerNbr,
			autoEnrollFuture: modeOfAddingContracts === 'automatic'
		}
	}
}
