import { fetchComponentDetails } from '../services/fetchComponentDetails'
import { fetchUserId } from '../services/fetchUserId'
import { fetchPreRegisteredDetails } from '../services/fetchPreRegisteredDetails'
import { createUserId } from '../services/createUserId'
import { fetchEmailConfirmation } from '../services/fetchEmailConfirmation'
import { createAccount } from '../services/createAccount'
import { fetchSubmitFeedback } from '../services/fetchSubmitFeedback'
import { fetchContracts } from '../services/fetchContracts'
import { fetchSubmitContracts } from '../services/fetchSubmitContracts'
import {
	showRegistrationErrorMessage,
	showGeneralMessage,
	showCreateUserIdErrorMessage,
	showGeneralErrorMessage,
	hideAllMessages,
	showFeedbackMessage
} from './messages'
import { getTermsOfUse, getRegistrationTermsOfUse } from './termsAndPolicies'
import {
	WFINFOLEASE,
	RICOH,
	PLINFOLEASE,
	WFORMS,
	RFI,
	SBA,
	ONBOARD,
	DLO,
	COBO,
	EASYCONNECT
} from '../constants/businessSegmentBrandIds'
import { showLanguageDropDown } from './locale'

export function getComponentDetails() {
	const urlParams = getUrlParams()
	return dispatch => {
		return new Promise((resolve, reject) => {
			fetchComponentDetails(urlParams)
				.then(fetchComponentDetailsResponse => {
					if (fetchComponentDetailsResponse.success) {
						const {
							bob,
							wca,
							outageManagement,
							productName,
							auth,
							mode,
							footerUrl,
							cookieConsentUrl
						} = fetchComponentDetailsResponse
						dispatch(
							setComponentDetails({
								bob,
								wca,
								auth,
								mode,
								footerUrl,
								cookieConsentUrl,
								outageManagement,
								productName
							})
						)
					}
					resolve(fetchComponentDetailsResponse)
				})
				.catch(fetchComponentDetailsError => {
					const serverOutageMessage = 'Server error'
					dispatch(showGeneralErrorMessage(serverOutageMessage))
					reject(fetchComponentDetailsError)
				})
		})
	}
}

export function getUrlParams() {
	const params = new URLSearchParams(window.location.search)
	const mode = params.get('mode')
	const app = params.get('app')
	const auth = params.get('auth')
	const appParamString = app ? `&app=${app}` : ''
	const locale = params.get('locale')
	const localeParamString = locale ? `&locale=${locale}` : ''
	const authParamString = auth ? `&auth=${auth}` : ''
	if (mode) {
		return `?${authParamString}&mode=${mode}${localeParamString}${appParamString}`
	}
	if (locale) {
		return `?locale=${locale}${appParamString}`
	}
	if (app) {
		return `?app=${app}`
	}
	if (auth) {
		return `?auth=${auth}`
	}
	return ''
}

export const SET_COMPONENT_DETAILS = 'SET_COMPONENT_DETAILS'

export function setComponentDetails(componentDetails) {
	return {
		type: SET_COMPONENT_DETAILS,
		componentDetails
	}
}

export const SET_COMPONENT_DETAILS_OUTAGE = 'SET_COMPONENT_DETAILS_OUTAGE'

export function setComponentDetailsOutage() {
	return {
		type: SET_COMPONENT_DETAILS_OUTAGE
	}
}

export const SET_ONLINE_HELP_URL = 'SET_ONLINE_HELP_URL'

export function setOnlineHelpUrl(onlineHelpUrl) {
	return {
		type: SET_ONLINE_HELP_URL,
		onlineHelpUrl
	}
}

export const INITIALIZE_WCA = 'INITIALIZE_WCA'

export function initializeWca(wcaWidget) {
	return {
		type: INITIALIZE_WCA,
		wcaWidget
	}
}

export const SHOW_LOGIN = 'SHOW_LOGIN'

export function showLogin(widget) {
	return {
		type: SHOW_LOGIN,
		widget
	}
}

export const CLEAR_SIGNON_FIELDS = 'CLEAR_SIGNON_FIELDS'

export function clearSignOnFields() {
	return {
		type: CLEAR_SIGNON_FIELDS
	}
}

export const CLEAR_REGISTRATION_FIELDS = 'CLEAR_REGISTRATION_FIELDS'

export function clearRegistrationFields() {
	return {
		type: CLEAR_REGISTRATION_FIELDS
	}
}

export function invokeAuthenticateUser() {
	return (dispatch, getState) => {
		const { loginUserId, password } = getState().loginWidget.inputFields
		const wcaWidget = getState().loginWidget.wcaWidget
		const inputFields = {
			userId: loginUserId.value,
			password: password.value
		}
		const nuCaptchaAns = document.getElementById('nucaptcha-answer')
			? document.getElementById('nucaptcha-answer').value
			: ''
		if (nuCaptchaAns) {
			inputFields['nuCaptchaAns'] = nuCaptchaAns
		}
		wcaWidget.authenticateUser(inputFields)
		dispatch(clearSignOnFields())
		dispatch(showCaptchaForm())
	}
}

export const SHOW_CAPTCHA_FORM = 'SHOW_CAPTCHA_FORM'

export function showCaptchaForm() {
	return {
		type: SHOW_CAPTCHA_FORM
	}
}

export const HIDE_CAPTCHA_FORM = 'HIDE_CAPTCHA_FORM'

export function hideCaptchaForm() {
	return {
		type: HIDE_CAPTCHA_FORM
	}
}

export const UPDATE_ESIGN_CONSENT = 'UPDATE_ESIGN_CONSENT'

export function updateEsignConsent(showEsign) {
	return {
		type: UPDATE_ESIGN_CONSENT,
		showEsign
	}
}

export function invokeForgotPassword() {
	return (dispatch, getState) => {
		const { wcaWidget } = getState().loginWidget
		wcaWidget.invokeForgotPasswordFlow()
		const resetHideFieldErrors = {
			loginUserId: true,
			password: true,
			email: true
		}
		dispatch(setHideFieldErrors(resetHideFieldErrors))
	}
}

export function invokeNewUser() {
	return (dispatch, getState) => {
		const wcaWidget = getState().loginWidget.wcaWidget
		wcaWidget.invokeNewUserFlow()
	}
}

export function invokeResetPassword() {
	return (dispatch, getState) => {
		const wcaWidget = getState().loginWidget.wcaWidget
		wcaWidget.invokeResetPasswordFlow()
	}
}

export const UPDATE_SHOW_LOADING = 'UPDATE_SHOW_LOADING'

export function updateShowLoading(showLoading) {
	return {
		type: UPDATE_SHOW_LOADING,
		showLoading
	}
}

export function invokeUserRegistration(samlResponse) {
	return (dispatch, getState) => {
		const wcaWidget = getState().loginWidget.wcaWidget
		wcaWidget.invokeUserRegistrationFlow(samlResponse)
	}
}

export const UPDATE_INPUT = 'UPDATE_INPUT'

export function updateInput(elementId, inputValue) {
	return {
		type: UPDATE_INPUT,
		elementId,
		inputValue
	}
}

export const FOCUS_INPUT = 'FOCUS_INPUT'

export function focusInput(focusedElement) {
	return {
		type: FOCUS_INPUT,
		focusedElement
	}
}

export const BLUR_INPUT = 'BLUR_INPUT'

export function blurInput(elementId) {
	return {
		type: BLUR_INPUT,
		elementId
	}
}

export const SHOW_FORGOT_USER_ID = 'SHOW_FORGOT_USER_ID'

export function showForgotUserId() {
	return {
		type: SHOW_FORGOT_USER_ID
	}
}

export const HIDE_FORGOT_USER_ID = 'HIDE_FORGOT_USER_ID'

export function hideForgotUserId() {
	return {
		type: HIDE_FORGOT_USER_ID
	}
}

export function getUserId() {
	return (dispatch, getState) => {
		const emailAddress = getState().loginWidget.inputFields.emailForgotUser
			.value
		const contractNumber = getState().loginWidget.inputFields.contractNumber
			.value
		const customerNumberRicohForgotUserId = getState().loginWidget.inputFields
			.customerNumberRicohForgotUserId.value
		const contractNumberRicohForgotUserId = getState().loginWidget.inputFields
			.contractNumberRicohForgotUserId.value
		const invoiceNumberRicohForgotUserId = getState().loginWidget.inputFields
			.invoiceNumberRicohForgotUserId.value
		const { mode } = getState().loginWidget
		const contactPhoneNumber = getState().loginWidget.inputFields.phoneNumber
			.value
		let getUserIdPayload
		if (mode === RICOH) {
			getUserIdPayload = {
				emailAddress,
				customerNbr: customerNumberRicohForgotUserId,
				contractId: contractNumberRicohForgotUserId,
				invoiceId: invoiceNumberRicohForgotUserId,
				deviceFingerPrint: ''
			}
		} else if (mode === WFINFOLEASE || mode === PLINFOLEASE) {
			getUserIdPayload = {
				emailAddress,
				acctSchedule: contractNumber
			}
		} else if (
			mode === SBA ||
			mode === ONBOARD ||
			mode === DLO ||
			mode === COBO ||
			mode === EASYCONNECT
		) {
			getUserIdPayload = {
				email: null,
				emailAddress,
				contactPhoneNumber
			}
		} else {
			getUserIdPayload = {
				email: null,
				emailAddress
			}
		}
		dispatch(hideAllMessages())
		return new Promise((resolve, reject) => {
			fetchUserId(getUserIdPayload, mode)
				.then(fetchUserIdResponse => {
					dispatch(
						showGeneralMessage(
							'api-service-forgotUserId.message',
							'confirmation'
						)
					)
					dispatch(hideForgotUserId())
					resolve(fetchUserIdResponse)
				})
				.catch(fetchUserIdError => {
					dispatch(showGeneralErrorMessage(fetchUserIdError.error))
					reject(fetchUserIdError)
				})
		})
	}
}

export function getEmailConfirmation() {
	return (dispatch, getState) => {
		const requestBody = {}
		requestBody.emailAddress = getState().loginWidget.inputFields.email.value
		const { mode } = getState().loginWidget
		if (
			mode !== WFORMS &&
			mode !== ONBOARD &&
			mode !== SBA &&
			mode !== RFI &&
			mode !== DLO &&
			mode !== COBO &&
			mode !== EASYCONNECT
		) {
			const {
				customerNumber,
				contractNumber,
				invoiceNumber,
				serialNumber,
				customerNumberRicoh,
				contractNumberRicoh
			} = getState().loginWidget.inputFields

			requestBody.invoiceNbr = invoiceNumber.value || null

			if (mode !== RICOH) {
				requestBody.customerNbr = customerNumber.value || null
				requestBody.accountSchedule = contractNumber.value || null
				requestBody.serialNbr = serialNumber.value || null
			} else {
				requestBody.customerNbr = customerNumberRicoh.value || null
				requestBody.contractId = contractNumberRicoh.value || null
			}
		}
		if (
			mode === SBA ||
			mode === ONBOARD ||
			mode === DLO ||
			mode === COBO ||
			mode === EASYCONNECT
		) {
			const { phoneNumber } = getState().loginWidget.inputFields
			requestBody.contactPhoneNumber = phoneNumber.value
		}
		const contextByBrandId = getContextByBrandId(mode)
		return new Promise((resolve, reject) => {
			fetchEmailConfirmation(requestBody, contextByBrandId)
				.then(fetchEmailConfirmationResponse => {
					const { showEsign, langPrefEnabled } = fetchEmailConfirmationResponse
					if (fetchEmailConfirmationResponse.success) {
						if (
							mode === WFORMS ||
							mode === ONBOARD ||
							mode === SBA ||
							mode === DLO ||
							mode === COBO ||
							mode === EASYCONNECT
						) {
							dispatch(getRegistrationDetails(showEsign))
							dispatch(updateEsignConsent(showEsign))
						} else {
							const showTermsFooter = true
							dispatch(getTermsOfUse(showEsign, showTermsFooter))
							dispatch(updateEsignConsent(showEsign))
							dispatch(showLanguageDropDown(langPrefEnabled))
						}
					} else {
						dispatch(
							showRegistrationErrorMessage(
								composeMessageId(fetchEmailConfirmationResponse.messageCode)
							)
						)
					}
					resolve(fetchEmailConfirmationResponse)
				})
				.catch(fetchEmailConfirmationError => {
					dispatch(showGeneralErrorMessage(fetchEmailConfirmationError.error))
					reject(fetchEmailConfirmationError)
				})
		})
	}
}

export function getContextByBrandId(mode) {
	switch (mode) {
		case RFI:
		case WFORMS:
		case ONBOARD:
		case DLO:
		case COBO:
		case EASYCONNECT:
		case SBA:
			return 'commercialLending'
		case RICOH:
			return 'ricoh'
		case WFINFOLEASE:
		case PLINFOLEASE:
			return 'lease'
		default:
			return 'commercialLending'
	}
}

export function getRegistrationDetails(showEsign) {
	return dispatch => {
		return new Promise((resolve, reject) => {
			fetchPreRegisteredDetails()
				.then(registrationDetailsResponse => {
					if (registrationDetailsResponse.success) {
						dispatch(getRegistrationDetailsSuccess(registrationDetailsResponse))
						const showTermsFooter = true
						const showESignFooter = true
						dispatch(
							getRegistrationTermsOfUse(
								showEsign,
								showESignFooter,
								showTermsFooter
							)
						)
					} else {
						dispatch(
							showRegistrationErrorMessage(registrationDetailsResponse.message)
						)
					}
					resolve(registrationDetailsResponse)
				})
				.catch(registrationDetailsError => {
					dispatch(showRegistrationErrorMessage(registrationDetailsError.error))
					reject(registrationDetailsError)
				})
		})
	}
}

export const GET_REGISTRATION_DETAILS_SUCCESS =
	'GET_REGISTRATION_DETAILS_SUCCESS'

export function getRegistrationDetailsSuccess(sbaDetails) {
	return {
		type: GET_REGISTRATION_DETAILS_SUCCESS,
		sbaDetails
	}
}

export const CLOSE_FORGOT_USER_ID_RESPONSE = 'CLOSE_FORGOT_USER_ID_RESPONSE'

export function closeForgotUserIdResponse() {
	return {
		type: CLOSE_FORGOT_USER_ID_RESPONSE
	}
}

export const SHOW_CREATE_USER_ID = 'SHOW_CREATE_USER_ID'

export function showCreateUserId() {
	return {
		type: SHOW_CREATE_USER_ID
	}
}

export function submitNewUserId() {
	return (dispatch, getState) => {
		const { mode } = getState().loginWidget
		const userDetails = formatUserDetails(mode, getState())

		return new Promise((resolve, reject) => {
			createUserId(userDetails)
				.then(createUserIdResponse => {
					if (createUserIdResponse.success) {
						dispatch(hideCreateUserId())
						dispatch(submitNewUserIdSuccess(createUserIdResponse))
					} else {
						dispatch(
							showCreateUserIdErrorMessage(
								composeMessageId(createUserIdResponse.messageCode)
							)
						)
					}
					resolve(createUserIdResponse)
				})
				.catch(createUserIdError => {
					dispatch(showCreateUserId())
					reject(createUserIdError)
				})
		})
	}
}

export function formatUserDetails(mode, state) {
	const username = state.loginWidget.inputFields.newUserId.value
	if (
		mode === WFORMS ||
		mode === ONBOARD ||
		mode === SBA ||
		mode === DLO ||
		mode === COBO ||
		mode === EASYCONNECT
	) {
		return {
			...state.loginWidget.sbaDetails,
			username,
			phoneType: state.loginWidget.sbaDetails.contactPreferences,
			ext: '',
			mInitial: state?.loginWidget?.sbaDetails?.middleInitial || ''
		}
	} else {
		const username = state.loginWidget.inputFields.newUserIdNotCC.value
		const {
			firstName,
			middleInitial,
			lastName,
			title,
			company,
			phone,
			extension
		} = state.loginWidget.inputFields
		const { deviceType, languagePreference } = state.loginWidget
		const language =
			languagePreference &&
			typeof languagePreference === 'string' &&
			languagePreference.length > 1
				? languagePreference.slice(0, 2).toLowerCase()
				: 'en'
		return {
			username,
			firstName: firstName.value,
			mInitial: middleInitial.value,
			lastName: lastName.value,
			company: company.value,
			title: title.value,
			telephone: phone.value,
			ext: extension.value,
			fax: '',
			language,
			phoneType: deviceType,
			deliveryType: null
		}
	}
}

export const SUBMIT_NEW_USER_ID_SUCCESS = 'SUBMIT_NEW_USER_ID_SUCCESS'

export function submitNewUserIdSuccess(details) {
	return {
		type: SUBMIT_NEW_USER_ID_SUCCESS,
		message: details.message,
		messageCode: details.messageCode,
		showTextDeliveryMode: details.mobile,
		showEmailDeliveryMode: !!details.maskedEmail,
		maskedUserDetails: {
			maskedEmail: details.maskedEmail,
			maskedPhone: details.maskedPhone
		}
	}
}

export const HIDE_CREATE_USER_ID = 'HIDE_CREATE_USER_ID'

export function hideCreateUserId() {
	return {
		type: HIDE_CREATE_USER_ID
	}
}

export const HIDE_DELIVERY_MODE = 'HIDE_DELIVERY_MODE'

export function hideDeliveryMode() {
	return {
		type: HIDE_DELIVERY_MODE
	}
}

export const UPDATE_DELIVERY_MODE = 'UPDATE_DELIVERY_MODE'

export function updateDeliveryMode(deliveryMode) {
	return {
		type: UPDATE_DELIVERY_MODE,
		deliveryMode
	}
}

export function createNewAccount() {
	return (dispatch, getState) => {
		const deliveryMode = getState().loginWidget.deliveryMode
		return new Promise((resolve, reject) => {
			createAccount({ deliveryMode })
				.then(createAccountResponse => {
					dispatch(hideDeliveryMode())
					dispatch(updateShowLoading(true))
					if (createAccountResponse.success) {
						dispatch(invokeUserRegistration(createAccountResponse.samlresponse))
					} else {
						dispatch(showGeneralErrorMessage(composeMessageId('CLDP9046')))
						dispatch(updateShowLoading(false))
					}
					resolve(createAccountResponse)
				})
				.catch(createAccountError => {
					dispatch(hideDeliveryMode())
					dispatch(showGeneralErrorMessage(createAccountError.error))
					reject(createAccountError)
				})
		})
	}
}

export function composeMessageId(messageId) {
	if (!messageId) {
		return 'api-service-error.CLDP0000.message'
	}
	const messageIds = messageId.match(/([CLDP]{4}[0-9]{4})/g)
	if (messageIds && messageIds.length > 1) {
		return messageIds.map(message => `api-service-error.${message}.message`)
	}
	return `api-service-error.${messageId}.message`
}

export const SELECT_CUSTOMER_OR_CONTRACT = 'SELECT_CUSTOMER_OR_CONTRACT'

export function selectCustomerOrContract(customerOrContract) {
	return {
		type: SELECT_CUSTOMER_OR_CONTRACT,
		customerOrContract
	}
}

export const SELECT_CONTRACT_OR_INVOICE = 'SELECT_CONTRACT_OR_INVOICE'

export function selectContractOrInvoice(contractOrInvoice) {
	return {
		type: SELECT_CONTRACT_OR_INVOICE,
		contractOrInvoice
	}
}

export const SELECT_INVOICE_OR_SERIAL = 'SELECT_INVOICE_OR_SERIAL'

export function selectInvoiceOrSerial(invoiceOrSerial) {
	return {
		type: SELECT_INVOICE_OR_SERIAL,
		invoiceOrSerial
	}
}

export const SHOW_FEEDBACK = 'SHOW_FEEDBACK'

export function showFeedback() {
	return {
		type: SHOW_FEEDBACK
	}
}

export const HIDE_FEEDBACK = 'HIDE_FEEDBACK'

export function hideFeedback() {
	return {
		type: HIDE_FEEDBACK
	}
}

export function submitFeedback() {
	return (dispatch, getState) => {
		const {
			mode,
			allowContactRegardingFeedback,
			inputFields: { feedbackText, feedbackEmail }
		} = getState().loginWidget
		const feedback = {
			commentCardId: getCommentCardIdByBrand(mode),
			comments: feedbackText.value,
			additionalFieldResponses: {
				CommunicateCustomer: allowContactRegardingFeedback,
				Email: feedbackEmail.value
			},
			requestPageUrl: document.URL
		}
		return new Promise((resolve, reject) => {
			fetchSubmitFeedback(feedback, mode)
				.then(submitFeedbackResponse => {
					resolve(submitFeedbackResponse)
					const { success } = submitFeedbackResponse
					if (success) {
						dispatch(hideFeedback())
						dispatch(hideAllMessages())
						dispatch(
							showGeneralMessage(
								composeMessageId(submitFeedbackResponse.messageCode),
								'confirmation'
							)
						)
					} else {
						dispatch(
							showFeedbackMessage(
								composeMessageId(submitFeedbackResponse.messageCode)
							)
						)
					}
				})
				.catch(submitFeedbackError => {
					reject(submitFeedbackError)
					dispatch(
						showFeedbackMessage(composeMessageId(submitFeedbackError.error))
					)
				})
		})
	}
}

export function getCommentCardIdByBrand(mode) {
	switch (mode) {
		case WFINFOLEASE:
			return '2'
		case PLINFOLEASE:
			return '3'
		default:
			return '9'
	}
}

export const SET_DEVICE_TYPE = 'SET_DEVICE_TYPE'

export function setDeviceType(deviceType) {
	return {
		type: SET_DEVICE_TYPE,
		deviceType
	}
}

export const HIDE_SECURE_ACCOUNT = 'HIDE_SECURE_ACCOUNT'

export function hideSecureAccount() {
	return {
		type: HIDE_SECURE_ACCOUNT
	}
}

export const UPDATE_EXISTING_CONTRACTS = 'UPDATE_EXISTING_CONTRACTS'

export function updateExistingContracts(existingContracts) {
	return {
		type: UPDATE_EXISTING_CONTRACTS,
		existingContracts
	}
}

export const UPDATE_NEWLY_ADDED_CONTRACTS = 'UPDATE_NEWLY_ADDED_CONTRACTS'

export function updateNewlyAddedContracts(newlyAddedContracts) {
	return {
		type: UPDATE_NEWLY_ADDED_CONTRACTS,
		newlyAddedContracts
	}
}

export function getContracts() {
	return (dispatch, getState) => {
		return new Promise((resolve, reject) => {
			const { mode } = getState().loginWidget
			fetchContracts(mode)
				.then(response => {
					const { fetchContractsResponse } = response
					if (fetchContractsResponse.success) {
						const { contracts } = response
						const {
							success,
							message,
							messageCode,
							...contractsInInitialForm
						} = fetchContractsResponse
						dispatch(setContracts(contracts, contractsInInitialForm))
					} else {
						const { message } = fetchContractsResponse
						dispatch(showGeneralErrorMessage(message))
					}
					resolve(fetchContractsResponse)
				})
				.catch(fetchContractsError => {
					dispatch(showGeneralErrorMessage(fetchContractsError.error))
					reject(fetchContractsError)
				})
		})
	}
}

export const SET_CONTRACTS = 'SET_CONTRACTS'

export function setContracts(contracts, contractsInInitialForm) {
	return {
		type: SET_CONTRACTS,
		contracts,
		contractsInInitialForm
	}
}

export const DISABLE_SECURE_SUBMIT_BUTTON = 'DISABLE_SECURE_SUBMIT_BUTTON'

export function disableSecureSubmitButton() {
	return {
		type: DISABLE_SECURE_SUBMIT_BUTTON
	}
}

export const ENABLE_SECURE_SUBMIT_BUTTON = 'ENABLE_SECURE_SUBMIT_BUTTON'

export function enableSecureSubmitButton() {
	return {
		type: ENABLE_SECURE_SUBMIT_BUTTON
	}
}

export function beginNuDataMonitoring() {
	return (dispatch, getState) => {
		const wcaWidget = getState().loginWidget.wcaWidget
		wcaWidget.loadNuDataScript()
		wcaWidget.beginBehaviouralMonitoring()
	}
}

export function submitContracts() {
	return (dispatch, getState) => {
		const {
			mode,
			existingContracts,
			newlyAddedContracts,
			modeOfAddingContracts,
			contractsInInitialForm
		} = getState().loginWidget
		const contracts = {
			existingContracts,
			newlyAddedContracts,
			modeOfAddingContracts
		}
		dispatch(disableSecureSubmitButton())
		return new Promise((resolve, reject) => {
			fetchSubmitContracts(mode, contracts, contractsInInitialForm)
				.then(fetchSubmitContractsResponse => {
					const { success, nextPage, message } = fetchSubmitContractsResponse
					if (success === true && nextPage) {
						dispatch(submitContractsSuccess())
						dispatch(enableSecureSubmitButton())
						window.location.replace(nextPage)
					} else {
						dispatch(showGeneralErrorMessage(message))
						dispatch(enableSecureSubmitButton())
					}
					resolve(fetchSubmitContractsResponse)
				})
				.catch(fetchSubmitContractsError => {
					dispatch(showGeneralErrorMessage(fetchSubmitContractsError.error))
					dispatch(enableSecureSubmitButton())
					reject(fetchSubmitContractsError)
				})
		})
	}
}

export const SUBMIT_CONTRACTS_SUCCESS = 'SUBMIT_CONTRACTS_SUCCESS'

export function submitContractsSuccess() {
	return {
		type: SUBMIT_CONTRACTS_SUCCESS
	}
}

export const SET_MODE_OF_ADDING_CONTRACTS = 'SET_MODE_OF_ADDING_CONTRACTS'

export function setModeOfAddingContracts(modeOfAddingContracts) {
	return {
		type: SET_MODE_OF_ADDING_CONTRACTS,
		modeOfAddingContracts
	}
}

export const SET_IS_VALID_NICKNAME = 'SET_IS_VALID_NICKNAME'

export function setIsValidNickname(isValidNickname) {
	return {
		type: SET_IS_VALID_NICKNAME,
		isValidNickname
	}
}

export const UPDATE_ALLOW_CONTACT_REGARDING_FEEDBACK =
	'UPDATE_ALLOW_CONTACT_REGARDING_FEEDBACK'

export function updateAllowContactRegardingFeedback(
	allowContactRegardingFeedback
) {
	return {
		type: UPDATE_ALLOW_CONTACT_REGARDING_FEEDBACK,
		allowContactRegardingFeedback
	}
}

export const CLEAR_INVOICE_OR_SERIAL = 'CLEAR_INVOICE_OR_SERIAL'

export function clearInvoiceOrSerial(invoiceOrSerial, mode) {
	return {
		type: CLEAR_INVOICE_OR_SERIAL,
		invoiceOrSerial,
		mode
	}
}

export const CLEAR_CUSTOMER_OR_CONTRACT = 'CLEAR_CUSTOMER_OR_CONTRACT'

export function clearCustomerOrContract(customerOrContract, mode) {
	return {
		type: CLEAR_CUSTOMER_OR_CONTRACT,
		customerOrContract,
		mode
	}
}

export const CLEAR_CONTRACT_OR_INVOICE = 'CLEAR_CONTRACT_OR_INVOICE'

export function clearContractOrInvoice(contractOrInvoice, mode) {
	return {
		type: CLEAR_CONTRACT_OR_INVOICE,
		contractOrInvoice,
		mode
	}
}

export const SET_HIDE_FIELD_ERRORS = 'SET_HIDE_FIELD_ERRORS'

export function setHideFieldErrors(hideFieldErrors) {
	return {
		type: SET_HIDE_FIELD_ERRORS,
		hideFieldErrors
	}
}

export const UPDATE_LANGUAGE_PREFERENCE = 'UPDATE_LANGUAGE_PREFERENCE'

export function updateLanguagePreference(languagePreference) {
	return {
		type: UPDATE_LANGUAGE_PREFERENCE,
		languagePreference
	}
}
