import React, { useEffect, useState } from 'react'
import { RadioButton, TextInput } from '@wf-wfria/ria'
import { RICOH } from '../constants/businessSegmentBrandIds'

export function ForgotUserIdRicohContractNumber({
	formatMessage,
	customerNumberRicohForgotUserId,
	contractNumberRicohForgotUserId,
	invoiceNumberRicohForgotUserId,
	onUpdateInput,
	onTextInputValidator,
	mode,
	customerNumberRicohInputClasses,
	contractNumberRicohInputClasses,
	invoiceNumberInputClasses,
	contractOrInvoice,
	focusedElement,
	onSelectContractOrInvoice
}) {
	const [key, setKey] = useState(Date.now())
	useEffect(() => {
		if (
			focusedElement !== 'email' &&
			focusedElement !== 'customerNumberRicohForgotUserId' &&
			focusedElement !== 'contractNumberRicohForgotUserId' &&
			focusedElement !== 'invoiceNumberRicohForgotUserId'
		) {
			const newKey = Date.now()
			setKey(newKey)
		}
	}, [focusedElement, setKey])

	if (mode !== RICOH) {
		return null
	}
	const contractOrInvoiceInputDisabled = !contractOrInvoice

	function onChange(event) {
		const { id, value } = event.target
		onUpdateInput(id, value)
	}

	function getCustomerOrContract(contractOrInvoice) {
		if (contractOrInvoice === 'invoiceRicoh') {
			return 'invoiceNumberRicohForgotUserId'
		}
		return 'contractNumberRicohForgotUserId'
	}

	function getContractOrInvoiceInputClasses(contractOrInvoice) {
		if (contractOrInvoice === 'contractRicoh') {
			return contractNumberRicohInputClasses
		}
		return invoiceNumberInputClasses
	}

	function invokeOnSelectContractOrInvoice({ value }) {
		onSelectContractOrInvoice(value)
	}

	function setInvoiceInputClasses(contractOrInvoice) {
		if (contractOrInvoice && contractOrInvoice !== 'invoiceRicoh') {
			const invoiceClasses = 'wf-col hide'
			return invoiceClasses
		}
		const defaultInvoiceClasses = 'wf-col'
		return defaultInvoiceClasses
	}

	function setContractInputClasses(contractOrInvoice) {
		if (contractOrInvoice === 'contractRicoh') {
			const contractClasses = 'wf-col'
			return contractClasses
		}
		const defaultContractClasses = 'wf-col hide'
		return defaultContractClasses
	}

	return (
		<div className="ForgotUserIdRichoCutomerAndContarct">
			<div className="wf-row">
				<TextInput
					aria-label={formatMessage({
						id: 'wca-form.new-user-registration.customer-number.label'
					})}
					label={formatMessage({
						id: 'wca-form.new-user-registration.customer-number.label'
					})}
					aria-required={true}
					required={true}
					id="customerNumberRicohForgotUserId"
					type="text"
					className={customerNumberRicohInputClasses}
					value={customerNumberRicohForgotUserId.value}
					onChange={onChange}
					labelClassName="wf-col wf-col--12 wf-col--mob-only-12"
					fieldClassName="wf-col wf-col--12 wf-col--mob-only-12"
					customValidators={[
						value =>
							onTextInputValidator('customerNumberRicohForgotUserId', value)
					]}
					nativeValidationMessages={{
						valueMissing: formatMessage({
							id: 'required-field.empty.error.label'
						})
					}}
				/>
			</div>
			<div className="wf-row ContractOrInvoiceSection">
				<div className="wf-col">
					<RadioButton
						name="contractOrInvoice"
						label={formatMessage({
							id: 'wca-form.new-user-registration.contract-number.label'
						})}
						id={'contractRicohRadio'}
						value={'contractRicoh'}
						onValueChange={invokeOnSelectContractOrInvoice}
						checked={contractOrInvoice === 'contractRicoh'}
					/>
					<RadioButton
						name="contractOrInvoice"
						label={formatMessage({
							id: 'wca-form.new-user-registration.invoice-number.label'
						})}
						id={'invoiceRicohRadio'}
						value={'invoiceRicoh'}
						onValueChange={invokeOnSelectContractOrInvoice}
						checked={contractOrInvoice === 'invoiceRicoh'}
					/>
				</div>
			</div>

			<div className="wf-row ContractOrInvoiceForgotUserId">
				<div className={setContractInputClasses(contractOrInvoice)}>
					<TextInput
						key={key}
						aria-label={formatMessage({
							id: 'wca-form.new-user-registration.contract-number.label'
						})}
						label={formatMessage({
							id: 'wca-form.new-user-registration.contract-number.label'
						})}
						labelHidden={true}
						aria-required={true}
						required={true}
						disabled={contractOrInvoiceInputDisabled}
						id={'contractNumberRicohForgotUserId'}
						type="text"
						value={contractNumberRicohForgotUserId.value}
						onChange={onChange}
						className={getContractOrInvoiceInputClasses('contractRicoh')}
						customValidators={[
							value =>
								onTextInputValidator(
									getCustomerOrContract('contractRicoh'),
									value
								)
						]}
						nativeValidationMessages={{
							valueMissing: formatMessage({
								id: 'required-field.empty.error.label'
							})
						}}
					/>
				</div>
				<div className={setInvoiceInputClasses(contractOrInvoice)}>
					<TextInput
						key={key}
						aria-label={formatMessage({
							id: 'wca-form.new-user-registration.invoice-number.label'
						})}
						label={formatMessage({
							id: 'wca-form.new-user-registration.invoice-number.label'
						})}
						labelHidden={true}
						aria-required={true}
						required={true}
						disabled={contractOrInvoiceInputDisabled}
						id={'invoiceNumberRicohForgotUserId'}
						type="text"
						value={invoiceNumberRicohForgotUserId.value}
						onChange={onChange}
						className={getContractOrInvoiceInputClasses('invoiceRicoh')}
						customValidators={[
							value =>
								onTextInputValidator(
									getCustomerOrContract('invoiceRicoh'),
									value
								)
						]}
						nativeValidationMessages={{
							valueMissing: formatMessage({
								id: 'required-field.empty.error.label'
							})
						}}
					/>
				</div>
			</div>
		</div>
	)
}
