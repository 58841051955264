import React, { useState, useEffect } from 'react'
import { RadioButton, TextInput } from '@wf-wfria/ria'

const customerNumberRef = React.createRef()
const contractNumberRef = React.createRef()

export function CustomerContractNumber({
	focusedElement,
	formatMessage,
	customerNumber,
	contractNumber,
	customerOrContract,
	customerNumberInputClasses,
	contractNumberInputClasses,
	onChange,
	onFocusFactory,
	onSelectCustomerOrContract,
	onTextInputValidator
}) {
	const [key, setKey] = useState(Date.now())
	useEffect(() => {
		if (
			focusedElement !== 'customerNumber' &&
			focusedElement !== 'contractNumber' &&
			focusedElement !== 'email' &&
			focusedElement !== 'invoiceNumber' &&
			focusedElement !== 'serialNumber'
		) {
			customerNumberRef.current.validate('reset')
			contractNumberRef.current.validate('reset')

			const newKey = Date.now()
			setKey(newKey)
		}
	}, [focusedElement, setKey])

	const contractValue = contractNumber.value
	const customerValue = customerNumber.value
	const customerOrContractInputDisabled = !customerOrContract

	const isSelectedCustomer = customerOrContract === 'customer'
	const isSelectedContract = customerOrContract === 'contract'
	const valueMissing = formatMessage({ id: 'required-field.empty.error.label' })

	function getCustomerOrContract(customerOrContract) {
		if (customerOrContract === 'customer') {
			return 'customerNumber'
		}
		return 'contractNumber'
	}

	function getCustomerOrContractInputClasses(customerOrContract) {
		if (customerOrContract === 'customer') {
			return customerNumberInputClasses
		}
		return contractNumberInputClasses
	}

	function invokeOnSelectCustomerOrContract(event) {
		onSelectCustomerOrContract(event.target.value)
	}

	function showHideCustomerTextInputField(customerOrContract) {
		const defaultShowHideCustomerClasses = 'wf-col'
		if (customerOrContract !== 'customer' && customerOrContract) {
			const showHideCustomerClasses = 'wf-col hide'
			return showHideCustomerClasses
		}
		return defaultShowHideCustomerClasses
	}

	function showHideContractTextInputField(customerOrContract) {
		const defaultShowHideContractClasses = 'wf-col hide'
		if (customerOrContract === 'contract') {
			const showHideContractClasses = 'wf-col'
			return showHideContractClasses
		}
		return defaultShowHideContractClasses
	}

	return (
		<div className="CustomerNumber WcaRegistrationFormGroup">
			<div className="wf-row wf-row--mob-1-column wf-row--tab-2-columns wf-row--desk-2-columns form-group">
				<div className="wf-col">
					<label htmlFor={getCustomerOrContract(customerOrContract)}>
						{formatMessage({
							id:
								'wca-form.new-user-registration.customer-and-contract-number.label'
						})}
					</label>
				</div>

				<div className="wf-col WcaFlexRowFormGroup">
					<RadioButton
						label={formatMessage({
							id: 'wca-form.new-user-registration.customer.label'
						})}
						value={'customer'}
						onChange={invokeOnSelectCustomerOrContract}
						checked={isSelectedCustomer}
					/>
					<RadioButton
						label={formatMessage({
							id: 'wca-form.new-user-registration.contract.label'
						})}
						value={'contract'}
						onChange={invokeOnSelectCustomerOrContract}
						checked={isSelectedContract}
					/>
				</div>
			</div>
			<div className="wf-row wf-row--mob-1-column wf-row--tab-2-columns wf-row--desk-2-columns form-group">
				<div className="wf-col" />
				<div className={showHideCustomerTextInputField(customerOrContract)}>
					<TextInput
						key={key}
						aria-label={formatMessage({
							id: 'wca-form.new-user-registration.customer-number.label'
						})}
						labelHidden={true}
						aria-required={true}
						required={true}
						disabled={customerOrContractInputDisabled}
						id={'customerNumber'}
						ref={customerNumberRef}
						type="text"
						size="25"
						value={customerValue}
						onChange={onChange}
						onFocus={onFocusFactory('WcaRegister')}
						className={getCustomerOrContractInputClasses('customer')}
						customValidators={[
							value =>
								onTextInputValidator(getCustomerOrContract('customer'), value)
						]}
						nativeValidationMessages={{
							valueMissing
						}}
					/>
				</div>
				<div className={showHideContractTextInputField(customerOrContract)}>
					<TextInput
						key={key}
						aria-label={formatMessage({
							id: 'wca-form.new-user-registration.contract-number.label'
						})}
						labelHidden={true}
						aria-required={true}
						required={true}
						disabled={customerOrContractInputDisabled}
						id={'contractNumber'}
						ref={contractNumberRef}
						type="text"
						size="25"
						value={contractValue}
						onChange={onChange}
						onFocus={onFocusFactory('WcaRegister')}
						className={getCustomerOrContractInputClasses('contract')}
						customValidators={[
							value =>
								onTextInputValidator(getCustomerOrContract('contract'), value)
						]}
						nativeValidationMessages={{
							valueMissing
						}}
					/>
				</div>
			</div>
		</div>
	)
}
