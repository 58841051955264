import React from 'react'
import { Select, MenuItem } from '@wf-wfria/ria'

export function HeaderMenu({
	show,
	locale,
	formatMessage,
	languageOptions = [],
	onLanguageChange
}) {
	if (!show) {
		return null
	}
	function onChange({ value }) {
		onLanguageChange(value)
	}
	return (
		<div className="HeaderMenu">
			<Select
				labelHidden
				value={locale}
				onValueChange={onChange}
				name="language"
				className="ocra-language-select"
			>
				{languageOptions.map(({ locale, language }) => (
					<MenuItem key={locale} value={locale}>
						{formatMessage({
							id: language
						})}
					</MenuItem>
				))}
			</Select>
		</div>
	)
}
