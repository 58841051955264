import React from 'react'
import Container from '@wf-wfria/ria/lib/Container'
import { FormattedMessage } from 'react-intl'

export function NoSignUpMessage() {
	return (
		<Container className="wf-col--desk-6 wf-col--tab-12 wf-col--mob-only-12 NoSignUpMessageContainer">
			<div className="container wf-row--padding">
				<div className="wf-col--12">
					<h2>
						<FormattedMessage id="ocra.no.new.application.message" />
					</h2>
				</div>
			</div>
		</Container>
	)
}
