export const APPEFMYACCOUNTS = 'appefmyaccounts'
export const APPEFMYACCOUNTSRICOH = 'appefmyaccountsricoh'
export const APPEFMYACCOUNTSPL = 'appefmyaccountspl'
export const APPEFMYACCOUNTSCC = 'appefmyaccountscc'
export const APPEFMYACCOUNTSRFI = 'appefmyaccountsrfi'
export const APPONBOARDING = 'apponboarding'
export const APPLENDMGMT = 'applendmgmt'
export const RFI = 'rfi'
export const RICOH = 'ricoh'
export const WFINFOLEASE = 'wfinfolease'
export const PLINFOLEASE = 'plinfolease'
export const WFORMS = 'wforms'
export const SBA = 'sba'
export const LGMTBOB = 'lgmtBob'
export const BOB = 'bob'
export const ONBOARD = 'onboard'
export const DLO = 'dlo'
export const COBO = 'cobo'
export const EASYCONNECT = 'easyconnect'
export const OCRANA = 'ocraNA'
export const OCRAEMEA = 'ocraEMEA'
export const OCRAANZ = 'ocraANZ'
export const OCRANAREVUS = 'ocraNAREVUS'
export const OCRANAREVCAN = 'ocraNAREVCAN'
export const OCRANABACUS = 'ocraNABACUS'
export const OCRANAPOLUS = 'ocraNAPOLUS'
export const OCRANACISUS = 'ocraNACISUS'
export const OCRANACISCAN = 'ocraNACISCAN'
export const OCRANAHUSUS = 'ocraNAHUSUS'
export const OCRANAKMFCAN = 'ocraNAKMFCAN'
export const OCRANABCFCAN = 'ocraNABCFCAN'
