import React, { Fragment } from 'react'
import { Button, Toolbar, TextInput, Select, MenuItem } from '@wf-wfria/ria'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import { ModalDialog } from './ModalDialog'
import { onFocusUserIdInput } from './RegistrationForm'
import { SystemMessages } from './SystemMessages'
import '@wf-wfria/ria/lib/css/Flexgrid/Flexgrid.css'
import {
	WFORMS,
	RFI,
	ONBOARD,
	SBA,
	WFINFOLEASE,
	PLINFOLEASE,
	RICOH,
	DLO,
	COBO,
	EASYCONNECT
} from '../constants/businessSegmentBrandIds'

const firstNameRef = React.createRef()
const lastNameRef = React.createRef()
const companyRef = React.createRef()
const phoneRef = React.createRef()
const userIdRef = React.createRef()

export function CreateUserIdFormContent({
	inputFields,
	deviceType,
	sbaDetails,
	messages,
	formatMessage,
	userIdClass,
	mode,
	languagePreference,
	showLanguageDropDown,
	onUpdateInput,
	onFocusInput,
	onDeviceTypeChange,
	onLanguagePreferenceChange,
	onCustomTextInputValidator
}) {
	const { newUserId } = inputFields

	function onFocus(event) {
		const targetId = event.target.id
		onFocusInput(targetId)
	}

	function onChange(event) {
		const id = event.target.id
		const value = event.target.value
		onUpdateInput(id, value)
	}

	function onTextInputValidator(elementId, inputValue) {
		return onCustomTextInputValidator(elementId, inputValue, formatMessage)
	}

	return (
		<div className="CreateUserIdFormContent">
			<CreateUserIdFormRequiredFieldInfo mode={mode} />
			<CreateUserIdFormInstructions mode={mode} />
			<CreateUserIdProfileInformation
				mode={mode}
				sbaDetails={sbaDetails}
				formatMessage={formatMessage}
			/>
			<div className="wf-row Message_FixedHeight">
				<SystemMessages messages={messages} />
			</div>
			<CreateUserIdInputCC
				mode={mode}
				formatMessage={formatMessage}
				newUserId={newUserId}
				userIdClass={userIdClass}
				onFocus={onFocus}
				onChange={onChange}
				onTextInputValidator={onTextInputValidator}
			/>
			<CreateUserIdInputNonCC
				mode={mode}
				formatMessage={formatMessage}
				inputFields={inputFields}
				deviceType={deviceType}
				userIdClass={userIdClass}
				languagePreference={languagePreference}
				showLanguageDropDown={showLanguageDropDown}
				onFocus={onFocus}
				onChange={onChange}
				onDeviceTypeChange={onDeviceTypeChange}
				onLanguagePreferenceChange={onLanguagePreferenceChange}
				onTextInputValidator={onTextInputValidator}
			/>
		</div>
	)
}

export function CreateUserIdFormRequiredFieldInfo({ mode }) {
	if (
		mode !== WFORMS &&
		mode !== ONBOARD &&
		mode !== SBA &&
		mode !== RFI &&
		mode !== DLO &&
		mode !== COBO &&
		mode !== EASYCONNECT
	) {
		return (
			<p>
				<FormattedMessage id="secure-account-form.required-field.text" />
			</p>
		)
	}
	return <Fragment />
}

export function CreateUserIdInputCC({
	mode,
	formatMessage,
	newUserId,
	userIdClass,
	onFocus,
	onChange
}) {
	if (
		mode !== WFORMS &&
		mode !== ONBOARD &&
		mode !== SBA &&
		mode !== RFI &&
		mode !== DLO &&
		mode !== COBO &&
		mode !== EASYCONNECT
	) {
		return <Fragment />
	}
	const valueMissing = formatMessage({ id: 'required-field.empty.error.label' })
	const newUserIdInputClasses = classNames(userIdClass, {
		HasInputViolation: newUserId.errorIds.length > 0
	})

	return (
		<div className="wf-row UserId">
			<div className="wf-col--mob-sm-12 wf-col--mob-12 wf-col--tab-6 wf-col--desk-6">
				<TextInput
					aria-label={formatMessage({
						id: 'create-user-id-form-content.user-id.aria-label'
					})}
					label={formatMessage({
						id: 'create-user-id-form-content.user-id.label'
					})}
					id="newUserId"
					aria-describedby="requirements"
					aria-required={true}
					required={true}
					value={newUserId.value}
					onFocus={onFocus}
					onChange={onChange}
					className={classNames(
						'textField wf-row api-xsmall-margin-bottom',
						newUserIdInputClasses
					)}
					labelClassName="wf-col wf-col--3 wf-col--mob-only-12 textAlignLeft"
					fieldClassName="wf-col wf-col--6 wf-col--mob-only-12"
					nativeValidationMessages={{
						valueMissing
					}}
				/>
			</div>

			<div
				className="Requirements wf-col--mob-sm-12 wf-col--mob-12 wf-col--tab-6 wf-col--desk-6"
				id="requirements"
			>
				<h2>
					<strong>
						<FormattedMessage id="create-user-id-form-content.user-id.requirements" />
					</strong>
				</h2>
				<span>
					<strong>
						<FormattedMessage id="create-user-id-form-content.user-id.requirements.must-contain.header-label" />
					</strong>
				</span>
				<ul>
					<li>
						<FormattedMessage id="create-user-id-form-content.user-id.requirements.must-contain.character-range" />
					</li>
				</ul>
				<span>
					<strong>
						<FormattedMessage id="create-user-id-form-content.user-id.requirements.cannot-contain.header-label" />
					</strong>
				</span>
				<ul>
					<li>
						<FormattedMessage id="create-user-id-form-content.user-id.requirements.cannot-contain.spaces" />
					</li>
					<li>
						<FormattedMessage id="create-user-id-form-content.user-id.requirements.cannot-contain.special-characters" />
					</li>
				</ul>
			</div>
		</div>
	)
}

export function GetCompany({
	mode,
	formatMessage,
	company,
	onChange,
	onTextInputValidator,
	valueMissing
}) {
	function getCustomValidatorKey() {
		if (mode === RICOH) {
			return 'companyRicoh'
		}
		return 'company'
	}

	return (
		<TextInput
			aria-label={formatMessage({
				id: 'create-user-id-form-content.company.aria-label'
			})}
			label={formatMessage({
				id: 'create-user-id-form-content.company.label'
			})}
			id="company"
			value={company.value}
			onChange={onChange}
			ref={companyRef}
			aria-required={true}
			required={true}
			customValidators={[
				value => onTextInputValidator(getCustomValidatorKey(), value)
			]}
			nativeValidationMessages={{
				valueMissing
			}}
		/>
	)
}

export function CreateUserIdInputNonCC({
	mode,
	formatMessage,
	inputFields,
	deviceType,
	languagePreference,
	showLanguageDropDown,
	onBlur,
	onChange,
	onDeviceTypeChange,
	onLanguagePreferenceChange,
	onTextInputValidator
}) {
	if (
		mode === WFORMS ||
		mode === ONBOARD ||
		mode === SBA ||
		mode === RFI ||
		mode === DLO ||
		mode === COBO ||
		mode === EASYCONNECT
	) {
		return <Fragment />
	}
	const valueMissing = formatMessage({ id: 'required-field.empty.error.label' })
	const {
		newUserIdNotCC,
		firstName,
		middleInitial,
		lastName,
		title,
		company,
		phone,
		extension
	} = inputFields
	const extensionDisabled = deviceType !== 'landline'

	function invokeDeviceTypeChange(event) {
		const deviceType = event.target.value
		onDeviceTypeChange(deviceType)
	}

	function invokeLanguagePreferenceChange(event) {
		const languagePreference = event.target.value
		onLanguagePreferenceChange(languagePreference)
	}

	return (
		<div className="wf-row UserId create-user-id-no-margin">
			<div className="wf-col--mob-sm-12 wf-col--mob-12 wf-col--tab-6 wf-col--desk-6">
				<div className="wf-col--mob-sm-12 wf-col--mob-12">
					<TextInput
						aria-label={formatMessage({
							id: 'create-user-id-form-content.first-name.aria-label'
						})}
						label={formatMessage({
							id: 'create-user-id-form-content.first-name.label'
						})}
						id="firstName"
						value={firstName.value}
						onChange={onChange}
						aria-required={true}
						required={true}
						ref={firstNameRef}
						customValidators={[
							value => onTextInputValidator('firstName', value)
						]}
						nativeValidationMessages={{
							valueMissing
						}}
					/>
				</div>
				<div className="wf-col--mob-sm-12 wf-col--mob-12 WcaExtraSmallInput">
					<TextInput
						aria-label={formatMessage({
							id: 'create-user-id-form-content.middle-initial.aria-label'
						})}
						label={formatMessage({
							id: 'create-user-id-form-content.middle-initial.label'
						})}
						id="middleInitial"
						value={middleInitial.value}
						onChange={onChange}
						customValidators={[
							value => onTextInputValidator('middleInitial', value)
						]}
					/>
				</div>
				<div className="wf-col--mob-sm-12 wf-col--mob-12">
					<TextInput
						aria-label={formatMessage({
							id: 'create-user-id-form-content.last-name.aria-label'
						})}
						label={formatMessage({
							id: 'create-user-id-form-content.last-name.label'
						})}
						id="lastName"
						value={lastName.value}
						onChange={onChange}
						aria-required={true}
						required={true}
						ref={lastNameRef}
						customValidators={[
							value => onTextInputValidator('lastName', value)
						]}
						nativeValidationMessages={{
							valueMissing
						}}
					/>
				</div>
				<div className="wf-col--mob-sm-12 wf-col--mob-12">
					<TextInput
						aria-label={formatMessage({
							id: 'create-user-id-form-content.title.aria-label'
						})}
						label={formatMessage({
							id: 'create-user-id-form-content.title.label'
						})}
						id="title"
						value={title.value}
						onChange={onChange}
						customValidators={[value => onTextInputValidator('title', value)]}
					/>
				</div>
				<div className="wf-col--mob-sm-12 wf-col--mob-12">
					<GetCompany
						mode={mode}
						formatMessage={formatMessage}
						company={company}
						onChange={onChange}
						onTextInputValidator={onTextInputValidator}
						valueMissing={valueMissing}
					/>
				</div>
				<div className="wf-col--mob-sm-12 wf-col--mob-12">
					<Select
						label={formatMessage({
							id: 'create-user-id-form-content.device-type.label'
						})}
						name="deviceType"
						value={deviceType}
						onChange={invokeDeviceTypeChange}
						id="deviceType"
						required={true}
						fieldClassName="wf-col wf-col--8 wf-col--mob-only-8 wf-col--tab-only-8"
					>
						<MenuItem value="landline">
							<FormattedMessage id="create-user-id-form-content.device-type.landline.label" />
						</MenuItem>
						<MenuItem value="cell">
							<FormattedMessage id="create-user-id-form-content.device-type.mobile.label" />
						</MenuItem>
					</Select>
				</div>
				<div className="wf-col--mob-sm-12 wf-col--mob-12 WcaFlexRowFormGroup">
					<div>
						<TextInput
							aria-label={formatMessage({
								id: 'create-user-id-form-content.phone.aria-label'
							})}
							label={formatMessage({
								id: 'create-user-id-form-content.phone.label'
							})}
							id="phone"
							value={phone.value}
							onBlur={onBlur}
							onChange={onChange}
							aria-required={true}
							required={true}
							ref={phoneRef}
							customValidators={[value => onTextInputValidator('phone', value)]}
							nativeValidationMessages={{
								valueMissing
							}}
						/>
					</div>
					<div className="marginLeft20 WcaExtraSmallInput">
						<TextInput
							aria-label={formatMessage({
								id: 'create-user-id-form-content.extension.aria-label'
							})}
							label={formatMessage({
								id: 'create-user-id-form-content.extension.label'
							})}
							id="extension"
							value={extension.value}
							disabled={extensionDisabled}
							onBlur={onBlur}
							aria-required={true}
							onChange={onChange}
							customValidators={[
								value => onTextInputValidator('extension', value)
							]}
						/>
					</div>
				</div>
				<LanguagePreference
					show={showLanguageDropDown}
					languagePreference={languagePreference}
					invokeLanguagePreferenceChange={invokeLanguagePreferenceChange}
					formatMessage={formatMessage}
				/>
				<div className="wf-col--mob-sm-12 wf-col--mob-12">
					<TextInput
						aria-label={formatMessage({
							id: 'create-user-id-form-content.user-id.aria-label'
						})}
						label={formatMessage({
							id: 'create-user-id-form-content.user-id.label'
						})}
						aria-describedby="requirements"
						id="newUserIdNotCC"
						value={newUserIdNotCC.value}
						onChange={onChange}
						aria-required={true}
						required={true}
						ref={userIdRef}
						customValidators={[
							value => onTextInputValidator('newUserIdNotCC', value)
						]}
						nativeValidationMessages={{
							valueMissing
						}}
					/>
				</div>
			</div>

			<div
				className="Requirements wf-col--mob-sm-12 wf-col--mob-12 wf-col--tab-6 wf-col--desk-6"
				id="requirements"
			>
				<h2>
					<strong>
						<FormattedMessage id="create-user-id-form-content.user-id.requirements" />
					</strong>
				</h2>
				<span>
					<strong>
						<FormattedMessage id="create-user-id-form-content.user-id.requirements.must-contain.header-label" />
					</strong>
				</span>
				<ul>
					<li>
						<FormattedMessage id="create-user-id-form-content.user-id.requirements.must-contain.character-range" />
					</li>
				</ul>
				<span>
					<strong>
						<FormattedMessage id="create-user-id-form-content.user-id.requirements.cannot-contain.header-label" />
					</strong>
				</span>
				<ul>
					<li>
						<FormattedMessage id="create-user-id-form-content.user-id.requirements.cannot-contain.spaces" />
					</li>
					<li>
						<FormattedMessage id="create-user-id-form-content.user-id.requirements.cannot-contain.special-characters" />
					</li>
				</ul>
			</div>
		</div>
	)
}

export function LanguagePreference({
	show,
	languagePreference,
	invokeLanguagePreferenceChange,
	formatMessage
}) {
	if (!show) {
		return <Fragment />
	}

	return (
		<div className="wf-col--mob-sm-12 wf-col--mob-12">
			<Select
				label={formatMessage({
					id: 'create-user-id-form-content.language-preference.label'
				})}
				name="languagePreference"
				value={languagePreference}
				onChange={invokeLanguagePreferenceChange}
				id="languagePreference"
				required={true}
				fieldClassName="wf-col wf-col--8 wf-col--mob-only-8 wf-col--tab-only-8"
			>
				<MenuItem value="English">
					<FormattedMessage id="create-user-id-form-content.language-preference.english.label" />
				</MenuItem>
				<MenuItem value="French">
					<FormattedMessage id="create-user-id-form-content.language-preference.french.label" />
				</MenuItem>
			</Select>
		</div>
	)
}

export function CreateUserIdFormFooter({
	mode,
	formIsValid,
	formatMessage,
	onHideCreateUserId,
	onSubmitNewUserId
}) {
	function CreateNewUserId() {
		if (formIsValid) {
			return onSubmitNewUserId()
		}
		if (mode === WFINFOLEASE || mode === PLINFOLEASE || mode === RICOH) {
			firstNameRef.current.validate()
			lastNameRef.current.validate()
			companyRef.current.validate()
			phoneRef.current.validate()
			userIdRef.current.validate()
		}
		onFocusUserIdInput('.UserId #newUserId')
	}
	return (
		<div>
			<Button
				className="CreateUserIdFormFooterSubmitButton"
				kind="primary"
				onClick={CreateNewUserId}
				label={formatMessage({
					id: 'create-user-id-form-footer.submit-button.label'
				})}
			/>
			<Button
				className="CreateUserIdFormFooterCancelButton"
				kind="chromeless"
				onClick={() => onHideCreateUserId()}
				label={formatMessage({
					id: 'create-user-id-form-footer.cancel-button.label'
				})}
			/>
		</div>
	)
}

export function CreateUserIdForm({
	createUserIdMessage,
	inputFields,
	deviceType,
	sbaDetails,
	showCreateUserIdForm,
	showUserIdConfirmationResponse,
	userIdErrorMessage,
	messages,
	formIsValid,
	intl,
	userIdClass,
	mode,
	languagePreference,
	showLanguageDropDown,
	onHideCreateUserId,
	onUpdateInput,
	onSubmitNewUserId,
	onFocusInput,
	onBlurInput,
	onDeviceTypeChange,
	onLanguagePreferenceChange,
	onCustomTextInputValidator
}) {
	const { formatMessage } = intl

	return (
		<ModalDialog
			footer={
				<CreateUserIdFormFooter
					onHideCreateUserId={onHideCreateUserId}
					onSubmitNewUserId={onSubmitNewUserId}
					formIsValid={formIsValid}
					mode={mode}
					formatMessage={formatMessage}
				/>
			}
			height="60%"
			width="950px"
			onClose={onHideCreateUserId}
			open={showCreateUserIdForm}
			title={formatMessage({ id: 'create-user-id-form.title' })}
		>
			<CreateUserIdFormContent
				createUserIdMessage={createUserIdMessage}
				inputFields={inputFields}
				deviceType={deviceType}
				sbaDetails={sbaDetails}
				showUserIdConfirmationResponse={showUserIdConfirmationResponse}
				userIdErrorMessage={userIdErrorMessage}
				messages={messages}
				formatMessage={formatMessage}
				userIdClass={userIdClass}
				mode={mode}
				languagePreference={languagePreference}
				showLanguageDropDown={showLanguageDropDown}
				onUpdateInput={onUpdateInput}
				onFocusInput={onFocusInput}
				onBlurInput={onBlurInput}
				onDeviceTypeChange={onDeviceTypeChange}
				onLanguagePreferenceChange={onLanguagePreferenceChange}
				onCustomTextInputValidator={onCustomTextInputValidator}
			/>
		</ModalDialog>
	)
}

export function CreateUserIdFormInstructions({ mode }) {
	if (
		mode === WFORMS ||
		mode === ONBOARD ||
		mode === SBA ||
		mode === RFI ||
		mode === DLO ||
		mode === COBO ||
		mode === EASYCONNECT
	) {
		return (
			<p className="CreateUserIdFormInstructions">
				<FormattedMessage id="create-user-id-form-content.instructions" />
			</p>
		)
	}
	return <Fragment />
}

export function CreateUserIdProfileInformation({
	mode,
	sbaDetails,
	formatMessage
}) {
	if (
		mode === WFORMS ||
		mode === ONBOARD ||
		mode === SBA ||
		mode === RFI ||
		mode === DLO ||
		mode === COBO ||
		mode === EASYCONNECT
	) {
		const { company, firstName, lastName, telephone } = sbaDetails
		return (
			<div className="ProfileInformation">
				<Toolbar
					className="ProfileInformation_Toolbar"
					aria-label={formatMessage({
						id:
							'create-user-id-form-content.profile-information.toolbar.aria-label'
					})}
					tabIndex="0"
				>
					<div className="user-info">
						<div className="wf-row--padding wf-row--mob-1-column wf-row--tab-2-columns wf-row--desk-3-columns">
							<div className="wf-col">
								<label>
									<FormattedMessage id="create-user-id-form-content.profile-information.first-name.label" />
								</label>
								<span>{firstName}</span>
							</div>
							<div className="wf-col">
								<label>
									<FormattedMessage id="create-user-id-form-content.profile-information.last-name.label" />
								</label>
								<span>{lastName}</span>
							</div>
							<div className="wf-col">
								<label>
									<FormattedMessage id="create-user-id-form-content.profile-information.company.label" />
								</label>
								<span>{company}</span>
							</div>
							<div className="wf-col">
								<label>
									<FormattedMessage id="create-user-id-form-content.profile-information.telephone.label" />
								</label>
								<span>{telephone}</span>
							</div>
						</div>
					</div>
				</Toolbar>
			</div>
		)
	}
	return <Fragment />
}
