import React from 'react'
import { TextInput } from '@wf-wfria/ria'
import {
	WFORMS,
	RICOH,
	RFI,
	ONBOARD,
	SBA,
	DLO,
	COBO,
	EASYCONNECT
} from '../constants/businessSegmentBrandIds'

export function ForgotUserIdContractNumber({
	formatMessage,
	contractNumber,
	onUpdateInput,
	onTextInputValidator,
	mode
}) {
	if (
		mode === WFORMS ||
		mode === ONBOARD ||
		mode === SBA ||
		mode === RICOH ||
		mode === RFI ||
		mode === DLO ||
		mode === COBO ||
		mode === EASYCONNECT
	) {
		return null
	}

	function onChange(event) {
		const id = event.target.id
		const value = event.target.value
		onUpdateInput(id, value)
	}

	const labelClassName =
		'wf-row wf-row--desk-3 wf-row--tab-3 wf-row--mob-only-12'
	const fieldClassName =
		'wf-row wf-row--desk-3 wf-row--tab-3 wf-row--mob-only-12'

	return (
		<div>
			<TextInput
				aria-label={formatMessage({
					id:
						'wca-form.forgot-user-id.account-schedule-or-contract-number.label'
				})}
				label={formatMessage({
					id:
						'wca-form.forgot-user-id.account-schedule-or-contract-number.label'
				})}
				aria-required={true}
				required={true}
				id="contractNumber"
				type="text"
				className="api-xsmall-margin-bottom"
				value={contractNumber.value}
				onChange={onChange}
				labelClassName={labelClassName}
				fieldClassName={fieldClassName}
				customValidators={[
					value => onTextInputValidator('contractNumber', value)
				]}
				nativeValidationMessages={{
					valueMissing: formatMessage({
						id: 'required-field.empty.error.label'
					})
				}}
			/>
		</div>
	)
}
