export function getOcraModeConfig(mode, locale) {
	switch (mode) {
		case 'ocraNA':
			return {
				region: 'NA',
				iconClass: 'OcraHeaderIconWells',
				languageOptions: getLanguages('NA')
			}
		case 'ocraEMEA':
			return {
				region: 'EMEA',
				iconClass: 'OcraHeaderIconWells',
				languageOptions: getLanguages('EMEA')
			}
		case 'ocraANZ':
			return {
				region: 'ANZ',
				iconClass: 'OcraHeaderIconWells',
				languageOptions: getLanguages('ANZ')
			}
		case 'ocraNAREVUS':
			return {
				region: 'NA',
				iconClass: 'OcraHeaderIconRev',
				languageOptions: getLanguages('NA')
			}
		case 'ocraNAREVCAN':
			return {
				region: 'NA',
				iconClass: 'OcraHeaderIconRev',
				languageOptions: getLanguages('NA')
			}
		case 'ocraNABACUS':
			return {
				region: 'NA',
				iconClass: 'OcraHeaderIconBac',
				languageOptions: getLanguages()
			}
		case 'ocraNAPOLUS':
			return {
				region: 'NA',
				iconClass: 'OcraHeaderIconPolaris',
				languageOptions: getLanguages('NA')
			}
		case 'ocraNACISUS':
			return {
				region: 'NA',
				iconClass: 'OcraHeaderIconCisco',
				languageOptions: getLanguages()
			}
		case 'ocraNACISCAN':
			return {
				region: 'NA',
				iconClass: 'OcraHeaderIconCisco',
				languageOptions: getLanguages('NA')
			}
		case 'ocraNAHUSUS':
			return {
				region: 'NA',
				iconClass: 'OcraHeaderIconHus',
				languageOptions: getLanguages('NA')
			}
		case 'ocraNAKMFCAN':
			return {
				region: 'NA',
				iconClass: 'OcraHeaderIconKmf',
				languageOptions: getLanguages('NA')
			}
		case 'ocraNABCFCAN':
			return {
				region: 'NA',
				iconClass:
					locale === 'fr' ? 'OcraHeaderIconBcfFr' : 'OcraHeaderIconBcfEn',
				languageOptions: getLanguages('NA')
			}
		case 'ocraNAHYGUS':
			return {
				region: 'NA',
				iconClass: 'OcraHeaderIconPolaris',
				languageOptions: getLanguages()
			}
		default:
			return {
				region: '',
				iconClass: '',
				languageOptions: getLanguages()
			}
	}
}

function getLanguages(region) {
	switch (region) {
		case 'NA':
			return [
				{
					locale: 'en',
					language: 'ocra.header.menu.locale.select.menu.item.english'
				},
				{
					locale: 'fr',
					language: 'ocra.header.menu.locale.select.menu.item.french'
				}
			]
		case 'EMEA':
			return [
				{
					locale: 'en_GB',
					language: 'ocra.header.menu.locale.select.menu.item.english'
				},
				{
					locale: 'fr_FR',
					language: 'ocra.header.menu.locale.select.menu.item.french'
				},
				{
					locale: 'de_DE',
					language: 'ocra.header.menu.locale.select.menu.item.german'
				}
			]
		case 'ANZ':
			return [
				{
					locale: 'au_AU',
					language: 'ocra.header.menu.locale.select.menu.item.english'
				}
			]
		default:
			return [
				{
					locale: 'en',
					language: 'ocra.header.menu.locale.select.menu.item.english'
				}
			]
	}
}
