import React, { useState } from 'react'
import { Button, Panel, Form } from '@wf-wfria/ria'
import { FormattedMessage } from 'react-intl'
import { FieldsBuilder } from './FieldsBuilder'

export function ForgotUserId({
	intl,
	label,
	className,
	title,
	fields,
	onForgotUserId
}) {
	const { formatMessage } = intl
	const [isPanelOpen, setPanelOpen] = useState(false)
	const [formValues, setFormValues] = useState({})
	const [isFormValid, setFormValidity] = useState(false)
	function onPanelOpen() {
		setPanelOpen(true)
	}
	function onPanelClose() {
		setPanelOpen(false)
	}
	function onChange(values) {
		setFormValues(values)
	}
	async function onSubmit() {
		await onForgotUserId(formValues)
		await setPanelOpen(false)
	}
	function handleValidation(isFormValid) {
		setFormValidity(isFormValid)
	}
	return (
		<Panel
			className="OCRA_ForgotUserId"
			onClose={onPanelClose}
			open={isPanelOpen}
			title={title}
			customStrings={{
				closeButtonAriaLabel: formatMessage({
					id: 'ocra.application.dialog.close'
				})
			}}
			trigger={
				<Button kind="simple" className={className} onClick={onPanelOpen}>
					{label}
				</Button>
			}
			animated
			footer={
				<div>
					<Button
						kind="primary"
						label={formatMessage({
							id: 'ocra.forgot-user-id.save.label'
						})}
						disabled={!isFormValid}
						onClick={onSubmit}
					/>
					<Button
						kind="standard"
						label={formatMessage({
							id: 'ocra.forgot-user-id.cancel.label'
						})}
						onClick={onPanelClose}
					/>
				</div>
			}
		>
			<Form onValidate={handleValidation}>
				<p>
					<FormattedMessage id="ocra.forgot-user-id-form.form-content.instruction" />
				</p>
				<FieldsBuilder
					fields={fields}
					values={formValues}
					onChange={onChange}
					formatMessage={formatMessage}
				/>
			</Form>
		</Panel>
	)
}
