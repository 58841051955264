import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Button } from '@wf-wfria/ria'
import { WFINFOLEASE, PLINFOLEASE } from '../constants/businessSegmentBrandIds'

export function ShowLanguageUserSignOn({
	mode,
	signOnLocaleUrl,
	formatMessage,
	showLanguageDropDown,
	onUserSignOnHere
}) {
	const signOnHereUSButton = (
		<Button
			kind="simple"
			className="ShowLanguageUserSignOn"
			onClick={() => onUserSignOnHere(signOnLocaleUrl)}
		>
			<FormattedMessage id="wca-form.button.us-users-sign-on-id.label" />
		</Button>
	)
	const signOnHereCanadaButton = (
		<Button
			kind="simple"
			className="ShowLanguageUserSignOn"
			onClick={() => onUserSignOnHere(signOnLocaleUrl)}
		>
			<span>
				{formatMessage({
					id: 'wca-form.button.canada-users-sign-on-id.label'
				})}
			</span>
			<br />
			<span>
				{formatMessage({
					id: 'wca-form.button.canada-users-sign-on-id.label-2'
				})}
			</span>
		</Button>
	)
	switch (mode) {
		case PLINFOLEASE:
		case WFINFOLEASE:
			return (
				<LanguageUserSignOn
					showLanguageDropDown={showLanguageDropDown}
					signOnHereCanadaButton={signOnHereCanadaButton}
					signOnHereUSButton={signOnHereUSButton}
				/>
			)
		default:
			return null
	}
}
export function LanguageUserSignOn({
	showLanguageDropDown,
	signOnHereUSButton,
	signOnHereCanadaButton
}) {
	if (showLanguageDropDown) {
		return (
			<div className="wf-row wf-row--mob-1-column wf-row--tab-2-columns wf-row--desk-2-columns form-group">
				<div className="wf-col hide-on-mobile"> </div>
				<div className="wf-col">{signOnHereUSButton}</div>
			</div>
		)
	} else {
		return (
			<div className="wf-row wf-row--mob-1-column wf-row--tab-2-columns wf-row--desk-2-columns form-group">
				<div className="wf-col hide-on-mobile"> </div>
				<div className="wf-col">{signOnHereCanadaButton}</div>
			</div>
		)
	}
}
