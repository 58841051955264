import React, { Fragment, useState } from 'react'
import { Panel, Button, Feedback } from '@wf-wfria/ria'
import { IconVerify, IconFootnote1 } from '@wf-wfria/ria-icons'
import { FormattedMessage } from 'react-intl'
import '@wf-wfria/ria/lib/css/Flexgrid/Flexgrid.css'
import {
	WFORMS,
	RFI,
	ONBOARD,
	SBA,
	DLO,
	COBO,
	EASYCONNECT,
	PLINFOLEASE,
	RICOH,
	WFINFOLEASE
} from '../constants/businessSegmentBrandIds'
import { BasicText, BasicAction } from '@wf-escon/react-data-driven-components'

export function ESignConsentContent({ termsToDisplay }) {
	return (
		<div id="ESignConsentContent">
			<BasicText text={termsToDisplay} inline={true} />
		</div>
	)
}

export function ESignConsentDownload({
	onDownloadEsignConsent,
	formatMessage
}) {
	return (
		<div data-wf-efmya-rfi-toc className="ESignConsentDownload">
			<BasicAction
				label={formatMessage({
					id: 'esign-consent.label.download-esign'
				})}
				display="link"
				onClick={onDownloadEsignConsent}
			/>
			<IconFootnote1 size="xsmall" />
		</div>
	)
}

export function ESignAcceptButton({
	showESignFooter,
	mode,
	onGetRegistrationTermsOfUse,
	onGetTermsOfUse
}) {
	const conditionalProps =
		mode !== WFORMS && mode !== RFI
			? { onClick: () => onGetTermsOfUse(false, showESignFooter, false) }
			: {
					onClick: () =>
						onGetRegistrationTermsOfUse(false, showESignFooter, false)
			  }
	return (
		<Button
			className="esign-accept-button"
			kind="primary"
			{...conditionalProps}
		>
			<FormattedMessage id="esign-consent.footer.button.accept.label" />
		</Button>
	)
}

export function ESignDeclineButton({
	onDeclineEsignConsent,
	formatMessage,
	mode
}) {
	const [panelOpen, setPanelOpen] = useState(false)
	function togglePanelOpen() {
		setPanelOpen(!panelOpen)
	}
	function declineContent(mode) {
		if (mode === 'sba') {
			return formatMessage({
				id: 'esign-consent-page.alert.message'
			})
		}
		if (mode === 'easyconnect' || mode === 'dlo' || mode === 'cobo') {
			return formatMessage({
				id: 'esign-consent-page.alert.message-easyconnect'
			})
		}
		return formatMessage({
			id: 'esign-consent-page.alert.message-onboard'
		})
	}
	return (
		<Panel
			onClose={togglePanelOpen}
			title={formatMessage({
				id: 'esign-consent-decline-verification'
			})}
			open={panelOpen}
			trigger={
				<span data-wf-efmya-payments-button>
					<Button
						className="esign-consent-decline-button"
						label={formatMessage({
							id: 'esign-consent.footer.button.decline.label'
						})}
						kind="chromeless"
						onClick={togglePanelOpen}
					/>
				</span>
			}
			footer={
				<div data-wf-efmya-customers-decline-footer>
					<Button
						kind="primary"
						label={formatMessage({
							id: 'esign-consent.footer.button.decline.label'
						})}
						onClick={onDeclineEsignConsent}
					/>
					<Button
						kind="chromeless"
						label={formatMessage({
							id: 'esign-consent.footer.button.cancel.label'
						})}
						onClick={togglePanelOpen}
					/>
				</div>
			}
		>
			<Feedback
				icon={<IconVerify size="large" className="esign-verify-icon" />}
				size="large"
			>
				<p>{declineContent(mode)}</p>
			</Feedback>
		</Panel>
	)
}

export function ESignConsentFooter({
	showESignFooter,
	mode,
	onGetTermsOfUse,
	onGetRegistrationTermsOfUse,
	onHideEsignConsent,
	onDeclineEsignConsent,
	formatMessage
}) {
	if (showESignFooter) {
		return (
			<div className="ESignConsentFooter">
				<span className="ESignConsentFooter_Text">
					<strong>
						<FormattedMessage id="esign-consent.footer.instruction" />
					</strong>
				</span>
				<ESignAcceptButton
					showESignFooter={showESignFooter}
					mode={mode}
					onGetRegistrationTermsOfUse={onGetRegistrationTermsOfUse}
					onGetTermsOfUse={onGetTermsOfUse}
				/>
				<ESignDeclineButton
					onDeclineEsignConsent={onDeclineEsignConsent}
					mode={mode}
					formatMessage={formatMessage}
				/>
			</div>
		)
	}
	if (
		mode !== WFORMS &&
		mode !== ONBOARD &&
		mode !== SBA &&
		mode !== RFI &&
		mode !== DLO &&
		mode !== COBO &&
		mode !== EASYCONNECT
	) {
		return (
			<div>
				<Button
					className="esign-consent-close-button"
					kind="primary"
					onClick={() => onHideEsignConsent()}
				>
					<FormattedMessage id="terms-and-conditions.footer.button.close.label" />
				</Button>
			</div>
		)
	}
	return <Fragment />
}

export function ESignConsent({
	show,
	showESignFooter,
	termsToDisplay,
	mode,
	onHideEsignConsent,
	onDownloadEsignConsent,
	onDeclineEsignConsent,
	onGetTermsOfUse,
	onGetRegistrationTermsOfUse,
	onShowCreateUserId,
	intl
}) {
	const { formatMessage } = intl
	if (!show) {
		return null
	}
	if (mode === WFINFOLEASE || mode === RICOH || mode === PLINFOLEASE) {
		return (
			<div className="ESignConsent">
				<h1>
					{formatMessage({
						id: 'esign-consent.header.modal-dialog.terms.title'
					})}
				</h1>
				<ESignConsentDownload
					formatMessage={formatMessage}
					onDownloadEsignConsent={onDownloadEsignConsent}
				/>
				<ESignConsentContent
					termsToDisplay={termsToDisplay}
					onShowCreateUserId={onShowCreateUserId}
				/>
				<div className="footNote">
					<IconFootnote1 size="xsmall" />
					<span
						dangerouslySetInnerHTML={{
							__html: formatMessage({
								id: 'esign-consent.footnote'
							})
						}}
					/>
				</div>
				<ESignConsentFooter
					showESignFooter={showESignFooter}
					onHideEsignConsent={onHideEsignConsent}
					mode={mode}
					onDeclineEsignConsent={onDeclineEsignConsent}
					onShowCreateUserId={onShowCreateUserId}
					onGetTermsOfUse={onGetTermsOfUse}
					onGetRegistrationTermsOfUse={onGetRegistrationTermsOfUse}
					formatMessage={formatMessage}
				/>
			</div>
		)
	}
	return (
		<Panel
			className="ESignConsent"
			footer={
				<div className="footNote">
					<IconFootnote1 size="xsmall" />
					<span
						dangerouslySetInnerHTML={{
							__html: formatMessage({
								id: 'esign-consent.footnote'
							})
						}}
					/>

					<ESignConsentFooter
						showESignFooter={showESignFooter}
						onHideEsignConsent={onHideEsignConsent}
						onDeclineEsignConsent={onDeclineEsignConsent}
						onShowCreateUserId={onShowCreateUserId}
						onGetRegistrationTermsOfUse={onGetRegistrationTermsOfUse}
						onGetTermsOfUse={onGetTermsOfUse}
						mode={mode}
						formatMessage={formatMessage}
					/>
				</div>
			}
			onClose={onHideEsignConsent}
			open={show}
			title={formatMessage({
				id: 'esign-consent.header.modal-dialog.terms.title'
			})}
			height="60%"
			elementToFocusOnClose=""
		>
			<ESignConsentDownload
				formatMessage={formatMessage}
				onDownloadEsignConsent={onDownloadEsignConsent}
			/>
			<ESignConsentContent
				termsToDisplay={termsToDisplay}
				onShowCreateUserId={onShowCreateUserId}
			/>
		</Panel>
	)
}
