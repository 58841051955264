export const VALIDATIONS = {
	email: {
		'forgot-user-id-form.email-validation.message': /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	},
	userEmail: {
		'forgot-user-id-form.email-validation.message': /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	},
	dob: {
		'forgot-user-id-form.dob-validation.message': /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])$/,
		'forgot-user-id-form.dob-validation.message-invalid': /^(?!02\/30|02\/31|04\/31|06\/31|09\/31|11\/31)/
	},
	phoneNumber: {
		'wca-form.new-user-registration.phoneNumber.label': /^\d{10}$/
	},
	nickName: {
		'secure-account-form.nickName-validation.message': /^[A-Za-z0-9-\\.\\,\\(\\)\\ \\*[\]]*$/
	},
	loginUserId: {
		'wca-form-content.login-user-id-error.not-empty': /^.+$/
	},
	password: {
		'wca-form-content.password-error.not-empty': /^.+$/
	},
	feedbackText: {
		'feedback-form-content.feedback-text-error.not-empty': /.*/
	},
	feedbackEmail: {
		'feedback-form-content.feedback-email-error.allowed-chars': /^(([^<>()[\]\\.,;:`~#$%^&*|/?"'!{}+=\s@"]+(\.[^<>()[\]\\.,;:`~#$%^&*|/?"'!{}+=\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	},
	customerNumber: {
		'create-user-id-form-content.customer-number-error.allowed-chars': /^[0-9]+$/i
	},
	customerNumberRicoh: {
		'create-user-id-form-content.customer-number-ricoh-error.allowed-chars': /^[0-9]+$/i
	},
	customerNumberRicohForgotUserId: {
		'create-user-id-form-content.customer-number-ricoh-error.allowed-chars': /^[0-9]+$/i
	},
	contractNumber: {
		'create-user-id-form-content.contract-number-error.allowed-chars': /\d{3}-\d{7}-\d{3}$/
	},
	contractNumberRicoh: {
		'create-user-id-form-content.contract-number-ricoh-error.allowed-chars': /^[a-zA-Z0-9]+$/i
	},
	contractNumberRicohForgotUserId: {
		'create-user-id-form-content.contract-number-ricoh-error.allowed-chars': /^[a-zA-Z0-9]+$/i
	},
	invoiceNumber: {
		'create-user-id-form-content.invoice-number-error.allowed-chars': /^[0-9]+$/
	},
	invoiceNumberRicohForgotUserId: {
		'create-user-id-form-content.invoice-number-error.allowed-chars': /^[0-9]+$/
	},
	serialNumber: {
		'create-user-id-form-content.serial-number-error.allowed-chars': /^[a-zA-Z0-9]+$/i
	},
	newUserId: {
		'create-user-id-form-content.user-id-error.min-max-length': /^.{5,20}$/,
		'create-user-id-form-content.user-id-error.allowed-chars': /^$|^[a-zA-Z0-9._-]+$/i
	},
	firstName: {
		'create-user-id-form-content.first-name-error.allowed-chars': /^[a-zA-Z,.\s-]+$/
	},
	middleInitial: {
		'create-user-id-form-content.middle-initial-error.allowed-chars': /^$|^[A-Za-z]+$/
	},
	lastName: {
		'create-user-id-form-content.last-name-error.allowed-chars': /^[a-zA-Z.\s-]+$/
	},
	title: {
		'create-user-id-form-content.title-error.allowed-chars': /^$|^[A-Za-z.\s-]+$/
	},
	company: {
		'create-user-id-form-content.company-error.allowed-chars': /^[a-zA-Z0-9&,.()'\s-]+$/
	},
	companyRicoh: {
		'create-user-id-form-content.companyRicoh-error.allowed-chars': /^[A-Za-z0-9&.,()'\s-]+$/
	},
	phone: {
		'create-user-id-form-content.phone-error.allowed-chars': /^\d{10}$/
	},
	extension: {
		'create-user-id-form-content.extension-error.allowed-chars': /^\d{3,4}$/
	},
	emailForgotUser: {
		'forgot-user-id-form.email-validation.message': /^(([^<>()[\]\\.,;:`~#$%^&*|/?"'!{}+=\s@"]+(\.[^<>()[\]\\.,;:`~#$%^&*|/?"'!{}+=\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	},
	emailInput: {
		'forgot-user-id-form.email-validation.message': /^(([^<>()[\]\\.,;:`~#$%^&*|/?"'!{}+=\s@"]+(\.[^<>()[\]\\.,;:`~#$%^&*|/?"'!{}+=\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	},
	dobInput: {
		'forgot-user-id-form.dob-validation.message': /^(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])$/,
		'forgot-user-id-form.dob-validation.message-invalid': /^(?!02\/30|02\/31|04\/31|06\/31|09\/31|11\/31)/
	},
	newUserIdNotCC: {
		'create-user-id-form-content.user-id-error.allowed-chars': /^$|^[a-zA-Z0-9._-]+$/i
	},
	fieldLabelTextInput: {
		customerNumberRicoh: {
			fieldLabelId: 'wca-form.new-user-registration.customer-number.label'
		},
		contractNumberRicoh: {
			fieldLabelId: 'wca-form.new-user-registration.contract-number.label'
		},
		invoiceNumber: {
			fieldLabelId: 'wca-form.new-user-registration.invoice-number.label'
		},
		customerNumber: {
			fieldLabelId: 'wca-form.new-user-registration.customer-number.label'
		},
		contractNumber: {
			fieldLabelId: 'wca-form.new-user-registration.contract-number.label'
		},
		serialNumber: {
			fieldLabelId: 'wca-form.new-user-registration.serial-number.label'
		},
		customerNumberRicohForgotUserId: {
			fieldLabelId: 'wca-form.new-user-registration.customer-number.label'
		},
		contractNumberRicohForgotUserId: {
			fieldLabelId: 'wca-form.new-user-registration.contract-number.label'
		},
		invoiceNumberRicohForgotUserId: {
			fieldLabelId: 'wca-form.new-user-registration.invoice-number.label'
		},
		firstName: {
			fieldLabelId: 'create-user-id-form-content.first-name.label'
		},
		middleInitial: {
			fieldLabelId: 'create-user-id-form-content.middle-initial.label'
		},
		title: {
			fieldLabelId: 'create-user-id-form-content.title.label'
		},
		company: {
			fieldLabelId: 'create-user-id-form-content.company.label'
		},
		companyRicoh: {
			fieldLabelId: 'create-user-id-form-content.company.label'
		},
		lastName: {
			fieldLabelId: 'create-user-id-form-content.last-name.label'
		},
		phone: {
			fieldLabelId: 'create-user-id-form-content.phone.label'
		},
		extension: {
			fieldLabelId: 'create-user-id-form-content.extension.label'
		},
		newUserIdNotCC: {
			fieldLabelId: 'create-user-id-form-content.user-id.label'
		}
	},
	/* Generic Rules for checking min-max rules as per element id name */
	minMaxTextInput: {
		customerNumber: {
			'create-user-id-form-content.customer-number-error.min-length': /^.{1,11}$/
		},
		customerNumberRicoh: {
			'create-user-id-form-content.customer-number-ricoh-error.min-length': /^.{1,11}$/
		},
		customerNumberRicohForgotUserId: {
			'create-user-id-form-content.customer-number-ricoh-forgot-user-id-error.min-length': /^.{1,11}$/
		},
		contractNumber: {
			'create-user-id-form-content.contract-number-error.min-length': /^.{13,15}$/
		},
		contractNumberRicoh: {
			'create-user-id-form-content.contract-number-ricoh-error.min-length': /^.{1,10}$/
		},
		contractNumberRicohForgotUserId: {
			'create-user-id-form-content.contract-number-ricoh-error.min-length': /^.{1,10}$/
		},
		invoiceNumber: {
			'create-user-id-form-content.invoice-number-error.min-length': /^.{5,20}$/
		},
		invoiceNumberRicohForgotUserId: {
			'create-user-id-form-content.invoice-number-error.min-length': /^.{5,20}$/
		},
		serialNumber: {
			'create-user-id-form-content.serial-number-error.min-length': /^.{3,20}$/
		},
		firstName: {
			'create-user-id-form-content.first-name-error.min-max-length': /^.{2,30}$/
		},
		middleInitial: {
			'create-user-id-form-content.middle-initial-error.min-max-length': /^.{1}$/
		},
		title: {
			'create-user-id-form-content.title-error.min-max-length': /^.{0,15}$/
		},
		company: {
			'create-user-id-form-content.company-error.min-max-length': /^.{2,50}$/
		},
		companyRicoh: {
			'create-user-id-form-content.companyRicoh-error.min-max-length': /^.{2,50}$/
		},
		lastName: {
			'create-user-id-form-content.last-name-error.min-max-length': /^.{2,30}$/
		},
		newUserIdNotCC: {
			'create-user-id-form-content.user-id-error.min-max-length': /^.{5,20}$/
		}
	}
}
