import { fetchUserId } from '../../services/fetchUserId'
import { fetchEmailConfirmation } from '../../services/fetchEmailConfirmation'
import { fetchPreRegisteredDetails } from '../../services/fetchPreRegisteredDetails'
import { fetchRegistrationTermsOfUse } from '../../services/fetchRegistrationTermsOfUse'
import { createUserId } from '../../services/createUserId'
import { createAccount } from '../../services/createAccount'
import { fetchContactDetails } from '../services/fetchContactDetails'

export function beginNuDataMonitoring() {
	return (dispatch, getState) => {
		const wcaWidget = getState().loginWidget.wcaWidget
		wcaWidget.loadNuDataScript()
		wcaWidget.beginBehaviouralMonitoring()
	}
}

export function signOn(params) {
	return async function signOnThunk(dispatch, getState) {
		const wcaWidget = getState().loginWidget.wcaWidget
		wcaWidget.authenticateUser(params)
		dispatch(showCaptchaForm())
	}
}

export function forgetPassword() {
	return async function forgetPasswordThunk(dispatch, getState) {
		const wcaWidget = getState().loginWidget.wcaWidget
		wcaWidget.invokeForgotPasswordFlow()
	}
}

export function forgotUserId(params) {
	return async function forgetUserIdThunk(dispatch) {
		try {
			await fetchUserId(params)
			const messages = [
				{
					type: 'confirmation',
					messageId: 'api-service-forgotUserId.message'
				}
			]
			dispatch(showSystemMessages(messages))
		} catch (error) {
			const messages = [
				{
					type: 'error',
					messageId: 'ocra.server.error'
				}
			]
			dispatch(showSystemMessages(messages))
		}
	}
}

export function registerUser(samlResponse) {
	return (dispatch, getState) => {
		const {
			loginWidget: { wcaWidget }
		} = getState()
		wcaWidget.invokeUserRegistrationFlow(samlResponse)
	}
}

export const SHOW_CAPTCHA_FORM = 'SHOW_CAPTCHA_FORM'

export function showCaptchaForm() {
	return {
		type: SHOW_CAPTCHA_FORM
	}
}

export const HIDE_CAPTCHA_FORM = 'HIDE_CAPTCHA_FORM'

export function hideCaptchaForm() {
	return {
		type: HIDE_CAPTCHA_FORM
	}
}

export const SHOW_SYSTEM_MESSAGES = 'SHOW_SYSTEM_MESSAGES'

export function showSystemMessages(messages) {
	return {
		type: SHOW_SYSTEM_MESSAGES,
		messages
	}
}

export const HIDE_SYSTEM_MESSAGES = 'HIDE_SYSTEM_MESSAGES'

export function hideSystemMessages() {
	return {
		type: HIDE_SYSTEM_MESSAGES
	}
}

export function updateMessageCode(messageCode) {
	if (messageCode.includes('CLDP1017')) {
		return messageCode.replace('CLDP1017', 'CLDP9042')
	}
	return messageCode
}

export function userRegistrationConfirmation(params) {
	return async function userRegistrationConfirmationThunk(dispatch, getState) {
		try {
			const { success, messageCode } = await fetchEmailConfirmation(
				params,
				'commercialLending'
			)
			if (success) {
				await dispatch(getRegistrationTermsOfUse())
				await dispatch(hideSystemMessages())
			} else {
				const updatedMessageCode = updateMessageCode(messageCode)
				const messages = [
					{
						type: 'error',
						messageId: composeMessageId(updatedMessageCode)
					}
				]
				dispatch(showSystemMessages(messages))
			}
		} catch (error) {
			const messages = [
				{
					type: 'error',
					messageId: 'ocra.server.error'
				}
			]
			dispatch(showSystemMessages(messages))
		}
	}
}

export function deliveryModeSubmit(params) {
	return async function deliveryModeSubmitThunk(dispatch, getState) {
		try {
			const { success, samlresponse } = await createAccount(params)
			if (success) {
				await dispatch(closeDeliveryModeModal())
				await dispatch(registerUser(samlresponse))
				await dispatch(hideSystemMessages())
			} else {
				const messages = [
					{
						type: 'error',
						messageId: composeMessageId('CLDP9046')
					}
				]
				dispatch(showSystemMessages(messages))
			}
		} catch (error) {
			const messages = [
				{
					type: 'error',
					messageId: 'ocra.server.error'
				}
			]
			dispatch(showSystemMessages(messages))
		}
	}
}

export const CLOSE_DELIVERY_MODE_MODAL = 'CLOSE_DELIVERY_MODE_MODAL'

export function closeDeliveryModeModal() {
	return {
		type: CLOSE_DELIVERY_MODE_MODAL
	}
}

export const SET_DELIVERY_MODE = 'SET_DELIVERY_MODE'

export function setDeliveryMode(mode) {
	return {
		type: 'SET_DELIVERY_MODE',
		mode
	}
}

export const SET_REGION = 'SET_REGION'

export function setRegion(region) {
	return {
		type: 'SET_REGION',
		region
	}
}

export function createNewUserId(params) {
	return async function saveUserIdThunk(dispatch, getState) {
		try {
			const { success, messageCode, ...response } = await createUserId(params)
			if (success) {
				dispatch(closeRegistrationDetailsModal())
				dispatch(setDeliveryMode(response))
				dispatch(hideSystemMessages())
			} else {
				const messages = [
					{
						type: 'error',
						messageId: composeMessageId(messageCode)
					}
				]
				dispatch(showSystemMessages(messages))
			}
		} catch (error) {
			const messages = [
				{
					type: 'error',
					messageId: 'ocra.server.error'
				}
			]
			dispatch(showSystemMessages(messages))
		}
	}
}

export const CLOSE_REGISTRATION_DETAILS_MODAL =
	'CLOSE_REGISTRATION_DETAILS_MODAL'

export function closeRegistrationDetailsModal() {
	return {
		type: CLOSE_REGISTRATION_DETAILS_MODAL
	}
}

export const GET_REGISTRATION_DETAILS_SUCCESS =
	'GET_REGISTRATION_DETAILS_SUCCESS'

export function getRegistrationDetailsSuccess(registrationDetails) {
	return {
		type: 'GET_REGISTRATION_DETAILS_SUCCESS',
		registrationDetails
	}
}

export function getRegistrationDetails() {
	return async function getRegistrationDetailsThunk(dispatch, getState) {
		try {
			const {
				success,
				messageCode,
				...response
			} = await fetchPreRegisteredDetails()
			if (success) {
				dispatch(getRegistrationDetailsSuccess(response))
				dispatch(hideSystemMessages())
			} else {
				const messages = [
					{
						type: 'error',
						messageId: composeMessageId(messageCode)
					}
				]
				dispatch(showSystemMessages(messages))
			}
		} catch (error) {
			const messages = [
				{
					type: 'error',
					messageId: 'ocra.server.error'
				}
			]
			dispatch(showSystemMessages(messages))
		}
	}
}

export const SET_TERMS_OF_USE = 'SET_TERMS_OF_USE'

export function setTermsOfUse(termsOfUse) {
	return {
		type: SET_TERMS_OF_USE,
		termsOfUse
	}
}

export const HIDE_TERMS_OF_USE = 'HIDE_TERMS_OF_USE'

export function hideTermsOfUse() {
	return {
		type: HIDE_TERMS_OF_USE
	}
}

export const SET_TERMS_OF_USE_FOR_FOOTER = 'SET_TERMS_OF_USE_FOR_FOOTER'

export function setTermsOfUseForFooter(termsOfUse) {
	return {
		type: SET_TERMS_OF_USE_FOR_FOOTER,
		termsOfUse
	}
}

export function getRegistrationTermsOfUse(footerLink) {
	return async function getRegistrationTermsOfUseThunk(dispatch, getState) {
		try {
			const {
				loginWidget: {
					componentDetails: {
						wca: { brandId }
					}
				}
			} = getState()
			const { success, text } = await fetchRegistrationTermsOfUse(brandId)
			if (success && text) {
				if (footerLink) {
					dispatch(setTermsOfUseForFooter(text))
				} else {
					dispatch(setTermsOfUse(text))
				}
				dispatch(hideSystemMessages())
			} else {
				const messages = [
					{
						type: 'error',
						messageId: 'ocra.server.error'
					}
				]
				dispatch(showSystemMessages(messages))
			}
		} catch (error) {
			const messages = [
				{
					type: 'error',
					messageId: 'ocra.server.error'
				}
			]
			dispatch(showSystemMessages(messages))
		}
	}
}

export const GET_CONTACT_DETAILS_SUCCESS = 'GET_CONTACT_DETAILS_SUCCESS'

export function getContactDetailsSuccess(contactDetails) {
	return {
		type: GET_CONTACT_DETAILS_SUCCESS,
		contactDetails
	}
}

export function getContactDetails(region) {
	return async function getContactDetailsThunk(dispatch, getState) {
		try {
			const { success, contacts } = await fetchContactDetails({ region })
			if (success && contacts) {
				dispatch(getContactDetailsSuccess(contacts))
				dispatch(hideSystemMessages())
			} else {
				const messages = [
					{
						type: 'error',
						messageId: 'ocra.server.error'
					}
				]
				dispatch(showSystemMessages(messages))
			}
		} catch (error) {
			const messages = [
				{
					type: 'error',
					messageId: 'ocra.server.error'
				}
			]
			dispatch(showSystemMessages(messages))
		}
	}
}

export function composeMessageId(messageId) {
	const messageIds = messageId.match(/([CLDP]{4}[0-9]{4})/g)
	if (messageIds && messageIds.length > 1) {
		return messageIds.map(message => `api-service-error.${message}.message`)
	}
	return `api-service-error.${messageId}.message`
}
