import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { closeDeliveryModeModal, deliveryModeSubmit } from '../actions/login'
import { DeliveryMode } from '../components/DeliveryMode'

export const mapStateToProps = state => {
	const { messages, showDeliveryMode, mode } = state.ocra
	return {
		show: showDeliveryMode,
		mode,
		messages
	}
}

export const mapDispatchToProps = dispatch => {
	return {
		onClose() {
			dispatch(closeDeliveryModeModal())
		},
		onDeliveryModeSubmit(params) {
			dispatch(deliveryModeSubmit(params))
		}
	}
}

export default injectIntl(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(DeliveryMode)
)
