import React from 'react'
import {
	TextInput,
	Fieldset,
	RadioButton,
	Select,
	MenuItem
} from '@wf-wfria/ria'
import { BasicLabelChoice } from '@wf-escon/react-data-driven-components'

export function FieldsBuilder({
	formatMessage,
	fields,
	values,
	onChange,
	region
}) {
	function handleOnChange(name, value) {
		values = {
			...values,
			[name]: value
		}
		onChange(values)
	}
	function handleOnValueChange({ name, value }) {
		const params = new URLSearchParams(window.location.search)
		const mode = params.get('mode')
		const isModeEMEA = mode && mode.includes('ocraEMEA') && !params.has('faqs')
		const isModeANZ = mode && mode.includes('ocraANZ') && !params.has('faqs')
		if (name === 'telephone' && !isModeEMEA && !isModeANZ) {
			value = value.replace(/(\d{3})-?(\d{3})-?(\d{4})/, '$1-$2-$3')
		}
		handleOnChange(name, value)
	}

	return (
		<React.Fragment>
			{fields.map((field, index) => {
				const {
					tag,
					name,
					label: labelId,
					className,
					required,
					validationType,
					value = '',
					choices = [],
					show = true,
					disabled = false,
					placeholder = 'dropdown.placeholder.select'
				} = field
				const label = formatMessage({ id: labelId })
				if (!show) {
					return null
				}
				switch (tag) {
					case 'TextInput':
						return (
							<React.Fragment key={index}>
								<TextInput
									name={name}
									label={label}
									onValueChange={handleOnValueChange}
									className={className}
									required={required}
									value={values[name] || value}
									nativeValidationMessages={{
										valueMissing: formatMessage(
											{
												id: 'ocra.required.validation.message'
											},
											{
												fieldLabel: label
											}
										)
									}}
									customValidators={[
										value =>
											onInputValidation(
												formatMessage,
												value,
												label,
												validationType
											)
									]}
								/>
							</React.Fragment>
						)
					case 'RadioButton':
						return (
							<Fieldset
								key={index}
								required={required}
								customValidators={[
									value =>
										onInputValidation(
											formatMessage,
											value,
											label,
											validationType
										)
								]}
								legend={label}
								className={className}
							>
								{choices.map((choice, index) => {
									const { value, label: labelId, className } = choice
									const label = formatMessage({ id: labelId })
									return (
										<RadioButton
											key={index}
											name={name}
											value={value}
											className={className}
											onValueChange={handleOnValueChange}
											label={label}
											checked={values[name] === value}
										/>
									)
								})}
							</Fieldset>
						)
					case 'BasicLabelChoice':
						const choiceItems = choices.map((choice, i) => {
							const { label: labelId } = choice
							const label = formatMessage({ id: labelId })
							choice.label = label
							choice.key = i
							return choice
						})
						const helpText = formatMessage({
							id: 'ocra.create-user-id.country.help.text'
						})

						return (
							<React.Fragment key={index}>
								<BasicLabelChoice
									help={{
										tag: 'BasicHelp',
										text: helpText,
										ariaLabel: helpText,
										size: 'xsmall'
									}}
									tag="BasicLabelChoice"
									disabled={disabled}
									onValueChanged={handleOnValueChange}
									label={label}
									choices={choiceItems}
									className={className}
									required={required}
									value={value}
									name={name}
								/>
							</React.Fragment>
						)
					case 'Select':
						const choiceFields = filterChoicesByCountry(
							region,
							name,
							choices,
							values
						)
						const translatedChoiceFields = choiceFields.map(choice => ({
							...choice,
							label: formatMessage({ id: choice.label })
						}))
						const sortedTranslatedChoiceFields = field.sortAfterNone
							? [
									...translatedChoiceFields.filter(
										choice => choice.value === ''
									),
									...translatedChoiceFields
										.filter(choice => choice.value !== '')
										.sort((a, b) =>
											a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1
										)
							  ]
							: translatedChoiceFields

						return (
							<React.Fragment key={index}>
								<Select
									label={label}
									name={name}
									placeholder={formatMessage({ id: placeholder })}
									required={required}
									className={className}
									value={values[name] || value}
									onValueChange={handleOnValueChange}
									nativeValidationMessages={{
										valueMissing: formatMessage(
											{
												id: 'ocra.required.validation.message'
											},
											{
												fieldLabel: label
											}
										)
									}}
								>
									{sortedTranslatedChoiceFields.map((choice, index) => {
										const { value, label } = choice
										return (
											<MenuItem key={index} value={value}>
												{label}
											</MenuItem>
										)
									})}
								</Select>
							</React.Fragment>
						)

					default:
						return null
				}
			})}
		</React.Fragment>
	)
}

export function filterChoicesByCountry(region, name, choices, { country }) {
	if (region === 'NA' && name === 'language' && country) {
		return choices.filter(({ value }) => {
			if (value === 'fr_CA' && country === 'USA') {
				return false
			}
			return true
		})
	}
	return choices
}

export function onInputValidation(
	formatMessage,
	fieldValue,
	fieldLabel,
	validationType
) {
	if (validationType) {
		const { pattern, messageId } = validationType
		if (!pattern.test(fieldValue)) {
			return formatMessage(
				{
					id: messageId
				},
				{
					fieldLabel
				}
			)
		}
	}
	return null
}
