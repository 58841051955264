export const validationRule = {
	email: {
		pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
		messageId: 'ocra.validation.message'
	},
	userId: {
		pattern: /^$|^[a-zA-Z0-9._-]{5,20}$/i,
		messageId: 'ocra.allowed.chars.validation.message'
	},
	firstName: {
		pattern: /^[a-zA-Z,.'\s-]{2,30}$/,
		messageId: 'ocra.create-user-id.first-name-error.allowed-chars'
	},
	company: {
		pattern: /^[a-zA-Z0-9&,.()'\s-]{2,1000}$/,
		messageId: 'ocra.create-user-id.company-error.allowed-chars'
	},
	lastName: {
		pattern: /^[a-zA-Z,.'\s-]{2,30}$/,
		messageId: 'ocra.create-user-id.first-name-error.allowed-chars'
	},
	middleInitial: {
		pattern: /^[A-Za-z]{1}$/,
		messageId: 'ocra.create-user-id-.middle-initial-error.allowed-chars'
	},
	title: {
		pattern: /^[A-Za-z]+$/,
		messageId: 'ocra.create-user-id-.title-error.allowed-chars'
	},
	ext: {
		pattern: /^[0-9]{0,10}$/,
		messageId: 'ocra.create-user-id-.ext-error.allowed-chars'
	},
	countryCode: {
		pattern: /^[0-9]{0,3}$/,
		messageId: 'ocra.create-user-id-.country-code-error.allowed-chars'
	},
	telephone: {
		pattern: /^([0-9]{3})[-]?([0-9]{3})[-]?([0-9]{4})$/,
		messageId: 'ocra.create-user-id-.telephone-error.allowed-chars'
	},
	emeaCountryCode: {
		pattern: /^[0-9]{0,4}$/,
		messageId: 'ocra.create-user-id-.country-code-error.allowed-chars'
	}
}
