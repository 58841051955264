import React, { useEffect, useState } from 'react'
import { RadioButton, TextInput } from '@wf-wfria/ria'

const invoiceNumberRef = React.createRef()
const serialNumberRef = React.createRef()

export function InvoiceSerialNumber({
	focusedElement,
	formatMessage,
	invoiceNumber,
	serialNumber,
	invoiceOrSerial,
	invoiceNumberInputClasses,
	serialNumberInputClasses,
	onChange,
	onFocusFactory,
	onBlur,
	onSelectInvoiceOrSerial,
	onTextInputValidator
}) {
	const [key, setKey] = useState(Date.now())
	useEffect(() => {
		// this is only good for CC
		if (
			focusedElement !== 'invoiceNumber' &&
			focusedElement !== 'serialNumber' &&
			focusedElement !== 'email' &&
			focusedElement !== 'customerNumber' &&
			focusedElement !== 'contractNumber'
		) {
			invoiceNumberRef.current.validate('reset')
			serialNumberRef.current.validate('reset')
			const newKey = Date.now()
			setKey(newKey)
		}
	}, [focusedElement, setKey])

	const invoiceValue = invoiceNumber.value
	const serialValue = serialNumber.value
	const invoiceOrSerialInputDisabled = !invoiceOrSerial

	const isSelectedSerial = invoiceOrSerial === 'serial'
	const isSelectedInvoice = invoiceOrSerial === 'invoice'

	function getInvoiceOrSerial(invoiceOrSerial) {
		if (invoiceOrSerial === 'invoice') {
			return 'invoiceNumber'
		}
		return 'serialNumber'
	}

	function getInvoiceOrSerialInputClasses(invoiceOrSerial) {
		if (invoiceOrSerial === 'invoice') {
			return invoiceNumberInputClasses
		}
		return serialNumberInputClasses
	}

	function invokeOnSelectInvoiceOrSerial(event) {
		onSelectInvoiceOrSerial(event.target.value)
	}

	function showHideInvoiceTextInputField(customerOrContract) {
		const defaultShowHideSerialClasses = 'wf-col hide'
		if (customerOrContract === 'invoice') {
			const showHideSerialClasses = 'wf-col'
			return showHideSerialClasses
		}
		return defaultShowHideSerialClasses
	}

	function showHideSerialTextInputField(customerOrContract) {
		const defaultShowHideInvoiceClasses = 'wf-col hide'
		if (customerOrContract !== 'invoice') {
			const showHideInvoiceClasses = 'wf-col'
			return showHideInvoiceClasses
		}
		return defaultShowHideInvoiceClasses
	}
	return (
		<div className="InvoiceNumber WcaRegistrationFormGroup">
			<div className="wf-row wf-row--mob-1-column wf-row--tab-2-columns wf-row--desk-2-columns form-group">
				<div className="wf-col">
					<label htmlFor={getInvoiceOrSerial(invoiceOrSerial)}>
						{formatMessage({
							id:
								'wca-form.new-user-registration.invoice-and-serial-number.label'
						})}
					</label>
				</div>
				<div className="wf-col WcaFlexRowFormGroup">
					<RadioButton
						label={formatMessage({
							id: 'wca-form.new-user-registration.invoice.label'
						})}
						value={'invoice'}
						onChange={invokeOnSelectInvoiceOrSerial}
						checked={isSelectedInvoice}
					/>
					<RadioButton
						label={formatMessage({
							id: 'wca-form.new-user-registration.serial.label'
						})}
						value={'serial'}
						onChange={invokeOnSelectInvoiceOrSerial}
						checked={isSelectedSerial}
					/>
				</div>
			</div>
			<div className="wf-row wf-row--mob-1-column wf-row--tab-2-columns wf-row--desk-2-columns form-group">
				<div className="wf-col hide-on-mobile" />
				<div className={showHideInvoiceTextInputField(invoiceOrSerial)}>
					<TextInput
						key={key}
						aria-label={formatMessage({
							id: 'wca-form.new-user-registration.invoice-number.label'
						})}
						labelHidden={true}
						required={true}
						aria-required={true}
						disabled={invoiceOrSerialInputDisabled}
						id={'invoiceNumber'}
						ref={invoiceNumberRef}
						type="text"
						size="25"
						value={invoiceValue}
						onChange={onChange}
						onFocus={onFocusFactory('WcaRegister')}
						onBlur={onBlur}
						className={getInvoiceOrSerialInputClasses('invoice')}
						customValidators={[
							value =>
								onTextInputValidator(getInvoiceOrSerial('invoice'), value)
						]}
						nativeValidationMessages={{
							valueMissing: formatMessage({
								id: 'required-field.empty.error.label'
							})
						}}
					/>
				</div>
				<div className={showHideSerialTextInputField(invoiceOrSerial)}>
					<TextInput
						key={key}
						aria-label={formatMessage({
							id: 'wca-form.new-user-registration.serial-number.label'
						})}
						labelHidden={true}
						aria-required={true}
						required={true}
						disabled={invoiceOrSerialInputDisabled}
						id={'serialNumber'}
						ref={serialNumberRef}
						type="text"
						size="25"
						value={serialValue}
						onChange={onChange}
						onFocus={onFocusFactory('WcaRegister')}
						onBlur={onBlur}
						className={getInvoiceOrSerialInputClasses('serial')}
						customValidators={[
							value => onTextInputValidator(getInvoiceOrSerial('serial'), value)
						]}
						nativeValidationMessages={{
							valueMissing: formatMessage({
								id: 'required-field.empty.error.label'
							})
						}}
					/>
				</div>
			</div>
		</div>
	)
}
