import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { ForgotUserId } from '../components/ForgotUserId'
import { fields } from '../../helpers/ocraFieldsConfig'
import { forgotUserId } from '../actions/login'

export const mapStateToProps = () => {
	return {
		fields: fields.forgotUserId['ocra']
	}
}

export const mapDispatchToProps = dispatch => {
	return {
		onForgotUserId(params) {
			dispatch(forgotUserId(params))
		}
	}
}

export default injectIntl(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(ForgotUserId)
)
