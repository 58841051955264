import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { hideTermsOfUse } from '../actions/login'
import { TermsOfUse } from '../components/TermsOfUse'

export const mapStateToProps = state => {
	return {
		show: state.ocra.showTermsOfUse,
		content: state.ocra.termsOfUse
	}
}

export const mapDispatchToProps = dispatch => {
	return {
		onCloseTermsOfUse() {
			dispatch(hideTermsOfUse())
		}
	}
}

export default injectIntl(
	connect(
		mapStateToProps,
		mapDispatchToProps
	)(TermsOfUse)
)
